let header = document.getElementsByClassName('fixed-top');
let jumbotron = document.getElementsByClassName('jumbotron');

if (jumbotron.length === 0 && header.length !== 0) {
	header[0].classList.remove('no-bg');
	header[0].classList.add('bg-hr');
}

let snow = document.getElementsByClassName('g-snows');


window.addEventListener('scroll', function () {
	if ((pageYOffset > 5) && (jumbotron.length !== 0)) {
		header[0].classList.remove('no-bg');
		header[0].classList.add('bg-hr');
	} else if ((pageYOffset < 150) && (jumbotron.length !== 0)) {
		header[0].classList.add('no-bg');
		header[0].classList.remove('bg-hr');
	}
	if (snow && snow.length > 0) {
		if ((pageYOffset > 200)) {
			snow[0].classList.remove('g-snows-big');
		} else if ((pageYOffset < 199)) {
			snow[0].classList.add('g-snows-big');
		}
	}
});


// let header = document.getElementsByClassName('for-scroll');
// let jumbotron = document.getElementsByClassName('jumbotron');
//
// if (jumbotron.length === 0) {
// 	for (let i = 0; i < header.length; i++) {
// 		header[i].classList.remove('d-none');
// 	}
// }
//
// window.addEventListener('scroll', function () {
// 	if ((pageYOffset > 150) && (jumbotron.length !== 0)) {
// 		for (let i = 0; i < header.length; i++) {
// 			header[i].classList.remove('d-none');
// 		}
// 	} else if ((pageYOffset < 150) && (jumbotron.length !== 0)) {
// 		for (let i = 0; i < header.length; i++) {
// 			header[i].classList.add('d-none');
// 		}
// 	}
// });