const customCheckboxes = document.querySelectorAll(".custom-checkbox-wrapper__b");

if (customCheckboxes.length > 0) {
	customCheckboxes.forEach(checkbox => {
		checkbox.addEventListener('click', () => {
			const field = checkbox.parentElement.querySelector('.custom-checkbox-wrapper__input');
			field.checked = !field.checked;
		});
	});
}
