const Choices = require('choices.js');

function loadedLocations() {

    if(document.getElementsByClassName('locations-select').length > 0) {
        let choicesLocations = new Choices('.locations-select', {
            placeholderValue: '',
            searchPlaceholderValue: ''
        });

        let service = new google.maps.places.AutocompleteService();
        let maxSelected = 5;
        let timer;
        if (document.querySelector(".locations-select-container input.choices__input") !== null) {
            document.querySelector(".locations-select-container input.choices__input").addEventListener("keyup", function (e) {
                clearTimeout(timer);
                let val = this.value;
                timer = setTimeout(function () {
                    service.getPlacePredictions({input: val, types: ['(cities)']}, function (predictions, status) {
                        if (status != google.maps.places.PlacesServiceStatus.OK) return;
                        let choicesArray = [];
                    
                        predictions.forEach(function (elem, idx) {
                            // choicesLocations.addItem()
                            choicesArray.push({value: elem.place_id, label: elem.description});
                        });
                    
                        choicesLocations.setChoices(choicesArray, 'value', 'label');
                    });
                }, 2000);
            });
        }
    }
}

window['googlePlacesLoaded'] = loadedLocations;