var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-experience-block block-add-new-work-experience" },
    [
      _vm.experiences.length > 0 || _vm.showForm
        ? _c(
            "p",
            {
              staticClass: "new-job-add-block-wrap-title",
              attrs: { id: "blockExperience" },
            },
            [_vm._v("\n\t\t" + _vm._s(_vm.labels.title) + "\n\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showErrorCallout
        ? _c("div", { staticClass: "callout callout-danger bg-light p-3" }, [
            _c("span", [_vm._v("Problem occurred while saving your data.")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.experiences, function (experience, index) {
        return experience.company && experience.position
          ? _c("div", { staticClass: "work-experience-result-block" }, [
              _c("div", { staticClass: "form-group mb-0" }, [
                _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
                  _c("div", { staticClass: "new-job-add-block-wrap-row-left" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "new-job-add-block-wrap-row-right" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "work-experience-result-wrap mb-3",
                          class: [
                            index === _vm.currentExperienceIndex
                              ? "edit-block"
                              : "",
                          ],
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "work-experience-result-btns" },
                            [
                              _c("i", {
                                staticClass: "fas fa-pen",
                                on: {
                                  click: function ($event) {
                                    return _vm.editExperience(index)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fas fa-times",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeExperience(index)
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "work-experience-result-title" },
                            [_vm._v(_vm._s(experience.company))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "work-experience-result-autor" },
                            [_vm._v(_vm._s(experience.position))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "work-experience-result-description",
                            },
                            [_vm._v(_vm._s(experience.description))]
                          ),
                          _vm._v(" "),
                          experience.startDate
                            ? _c(
                                "p",
                                { staticClass: "work-experience-result-date" },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(experience.startDate)),
                                  ]),
                                  _vm._v("\n\t\t\t\t\t\t\t–\n\t\t\t\t\t\t\t"),
                                  experience.endDate
                                    ? _c("span", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(experience.endDate) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tStill working\n\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.showForm
        ? _c("div", [
            _c(
              "form",
              { attrs: { novalidate: "" }, on: { submit: _vm.saveExperience } },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "new-job-add-block-wrap-row",
                      class: [
                        _vm.hasError("companyName") ? "is-invalid-block" : "",
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "new-job-add-block-wrap-row-left" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "mb-2",
                              attrs: { for: "experience_companyName" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labels.company) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "new-job-add-block-wrap-row-right" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.companyName,
                                expression: "companyName",
                              },
                            ],
                            staticClass: "form-control",
                            class: [
                              _vm.hasError("companyName") ? "is-invalid" : "",
                            ],
                            attrs: {
                              type: "text",
                              id: "experience_companyName",
                              name: "experience[companyName]",
                              maxlength: "1024",
                            },
                            domProps: { value: _vm.companyName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.companyName = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "new-job-add-block-wrap-row",
                      class: [
                        _vm.hasError("position") ? "is-invalid-block" : "",
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "new-job-add-block-wrap-row-left" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "mb-2",
                              attrs: { for: "experience_position" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labels.position) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "new-job-add-block-wrap-row-right" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.position,
                                expression: "position",
                              },
                            ],
                            staticClass: "form-control",
                            class: [
                              _vm.hasError("position") ? "is-invalid" : "",
                            ],
                            attrs: {
                              type: "text",
                              id: "experience_position",
                              name: "experience[position]",
                              maxlength: "1024",
                            },
                            domProps: { value: _vm.position },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.position = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
                    _c(
                      "div",
                      { staticClass: "new-job-add-block-wrap-row-left" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mb-2",
                            attrs: { for: "experience_description" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.labels.description) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "new-job-add-block-wrap-row-right" },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.description,
                              expression: "description",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "experience_description",
                            name: "experience[description]",
                          },
                          domProps: { value: _vm.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.description = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "new-job-add-block-wrap-row",
                      class: [
                        _vm.hasError("startDate") ? "is-invalid-block" : "",
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "new-job-add-block-wrap-row-left" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "mb-2",
                              attrs: { for: "experience_startDateMonth" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labels.startDate) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "new-job-add-block-wrap-row-right" },
                        [
                          _c("div", { staticClass: "block-month-year" }, [
                            _c(
                              "div",
                              { staticClass: "wrap-form-lang-select" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.startDateMonth,
                                        expression: "startDateMonth",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: [
                                      _vm.hasError("startDate")
                                        ? "is-invalid"
                                        : "",
                                    ],
                                    attrs: {
                                      id: "experience_startDateMonth",
                                      name: "experience[startDateMonth]",
                                      "data-field": "exp_startDateMonth",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.startDateMonth = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", value: "" } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.labels.startMonth) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.monthsList,
                                      function (month, index) {
                                        return _c(
                                          "option",
                                          { domProps: { value: index } },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(month) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.startDateYear,
                                  expression: "startDateYear",
                                },
                              ],
                              staticClass: "form-control work-experience-year",
                              attrs: {
                                type: "number",
                                id: "experience_startDateYear",
                                name: "experience[startDateYear]",
                                min: "1971",
                                max: new Date().getFullYear(),
                              },
                              domProps: { value: _vm.startDateYear },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.startDateYear = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "custom-checkbox-wrapper" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.stillWorking,
                                    expression: "stillWorking",
                                  },
                                ],
                                staticClass: "custom-checkbox-wrapper__input",
                                attrs: {
                                  type: "checkbox",
                                  id: "experience_stillWorking",
                                  name: "stillWorking",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.stillWorking)
                                    ? _vm._i(_vm.stillWorking, null) > -1
                                    : _vm.stillWorking,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.stillWorking,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.stillWorking = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.stillWorking = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.stillWorking = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("b", {
                                staticClass: "custom-checkbox-wrapper__b",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "text-normal mb-0",
                              attrs: { for: "experience_stillWorking" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labels.stillWorking) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                !_vm.stillWorking
                  ? _c("div", { staticClass: "form-group mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass: "new-job-add-block-wrap-row",
                          class: [
                            _vm.hasError("endDate") ? "is-invalid-block" : "",
                          ],
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "new-job-add-block-wrap-row-left" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "mb-2",
                                  attrs: { for: "experience_endDateMonth" },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.labels.endDate) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "new-job-add-block-wrap-row-right" },
                            [
                              _c("div", { staticClass: "block-month-year" }, [
                                _c(
                                  "div",
                                  { staticClass: "wrap-form-lang-select" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.endDateMonth,
                                            expression: "endDateMonth",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: [
                                          _vm.hasError("endDate")
                                            ? "is-invalid"
                                            : "",
                                        ],
                                        attrs: {
                                          id: "experience_endDateMonth",
                                          name: "experience[endDateMonth]",
                                          "data-field": "exp_endDateMonth",
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.endDateMonth = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { disabled: "", value: "" },
                                          },
                                          [_vm._v(_vm._s(_vm.labels.endMonth))]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.monthsList,
                                          function (month, index) {
                                            return _c(
                                              "option",
                                              { domProps: { value: index } },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(month) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.endDateYear,
                                      expression: "endDateYear",
                                    },
                                  ],
                                  staticClass:
                                    "form-control work-experience-year",
                                  class: [
                                    _vm.hasError("endDate") ? "is-invalid" : "",
                                  ],
                                  attrs: {
                                    type: "number",
                                    id: "experience_endDateYear",
                                    name: "experience[endDateYear]",
                                    max: new Date().getFullYear(),
                                    min: _vm.startDateYear,
                                  },
                                  domProps: { value: _vm.endDateYear },
                                  on: {
                                    keyup: _vm.checkYear,
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.endDateYear = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-group mb-3" }, [
                  _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
                    _c("div", {
                      staticClass: "new-job-add-block-wrap-row-left",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "new-job-add-block-wrap-row-right for-line-mob",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: [
                              !_vm.companyName && !_vm.position
                                ? "btn-outline-success"
                                : "btn-outline-success",
                            ],
                            attrs: { type: "button" },
                            on: { click: _vm.saveExperience },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.labels.save) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-grey ml-4",
                            attrs: { type: "button" },
                            on: { click: _vm.cancelClick },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.labels.cancel) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.showForm
        ? _c("div", { staticClass: "form-group mb-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "new-job-add-block-wrap-row new-job-add-block-wrap-row__flex",
                class: { "d-none": _vm.experiences.length > 0 },
              },
              [
                _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                  !_vm.showForm
                    ? _c(
                        "p",
                        {
                          staticClass: "additional-information-block-title",
                          attrs: { disabled: !_vm.code },
                        },
                        [
                          !_vm.showLoader
                            ? _c("span", [_vm._v(_vm._s(_vm.labels.addTitle))])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showLoader
                            ? _c("i", {
                                staticClass: "fa fa-fw fa-spin fa-spinner",
                              })
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "new-job-add-block-wrap-row-right text-right",
                  },
                  [
                    !_vm.showForm
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn-show-additional-information-block",
                            attrs: { type: "button", disabled: !_vm.code },
                            on: { click: _vm.addExperience },
                          },
                          [_c("i", { staticClass: "fas fa-chevron-down" })]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.experiences.length > 0
              ? _c("div", { staticClass: "text-right mb-3" }, [
                  !_vm.showForm
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-primary",
                          attrs: { type: "button" },
                          on: { click: _vm.addExperience },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.labels.addTitle) +
                              "\n\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }