// toggle dropdown about company
const btnDropdownTitleAbout = document.querySelector('.dropdown-title-about');
const dropdownAboutText = document.querySelector('.dropdown-about-text');

if (btnDropdownTitleAbout) {
    btnDropdownTitleAbout.addEventListener('click', function () {
        /*btnDropdownTitleAbout.classList.toggle("active-el");
        dropdownAboutText.classList.toggle("d-none");*/
        console.log('activated/deactivated');
    });
}