let paymentFormEl = document.querySelector('form[name="payment_form"]');


if(paymentFormEl) {
	let checkedPaymentMethodEls = paymentFormEl.querySelector('input[type="radio"]:checked'),
		toggleTabEls = paymentFormEl.querySelectorAll('a[role="tab"][data-toggle="tab"]');

	if(toggleTabEls && toggleTabEls.length > 0) {
		toggleTabEls.forEach(function(toggleEl) {
			toggleEl.addEventListener('click', function(e) {

				let targetId = this.getAttribute('data-target-id');

				paymentFormEl.querySelector('input#input_' + targetId).checked = true;
			})
		})
	}

	if(checkedPaymentMethodEls) {
		let selectedTabEl = checkedPaymentMethodEls.getAttribute('id').split('input_');

		if (selectedTabEl && selectedTabEl.length > 0) {
			let switchEl = document.querySelector('a[data-target-id="' + selectedTabEl[1] + '"]');
		}
	}

	/*paymentFormEl.addEventListener('submit', function(e) {

		console.log('Stop');

		if(!checkedPaymentMethodEls) {

			e.preventDefault();

			let helpTextEl = paymentFormEl.querySelector('p[id="payment_form_payments_help"]');

			alert(helpTextEl);

			return false;
		}
	})*/
}
