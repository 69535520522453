let subscriptionFormEl = document.querySelector('form[name="offer_subscribe"]');

if(subscriptionFormEl) {
	let sendButtonEl = subscriptionFormEl.querySelector('button[type="submit"]');
	
	if(sendButtonEl) {
		sendButtonEl.addEventListener('click', function () {
			subscriptionsEvent('subscriptions', 'subscribed', 'jobs');
			
			return true;
		});
	}

	subscriptionFormEl.addEventListener('submit', function () {
		subscriptionsEvent('subscriptions', 'subscribed', 'jobs');
	})
}

function subscriptionsEvent(category, action, label) {
	gtag('event', action, {
		'event_category': category,
		'event_label': label
	});
}