let dashboardEl = document.getElementsByClassName('dashboard');

if(dashboardEl && dashboardEl.length > 0) {
	let formEl = dashboardEl[0].querySelector('form[name="offer"]');
	
	if(formEl) {
		let pricingSwitchers = formEl.querySelectorAll('input[name="offer[pricing][type]"]');
		
		if(pricingSwitchers && pricingSwitchers.length > 0) {
			pricingSwitchers.forEach(function(inputEl) {
				inputEl.addEventListener('change', function(e) {
					let type = e.target.value;
				
					formEl.getElementsByClassName('pro-offer').forEach(function(item) {
						item.classList.toggle('d-none');
					})
				})
			})
		}
	}
}