var $form = $('#topUpForm');

if ($form.length) {
	var currency = $('#current-currency').text();
	var $amount = $form.find('input#top_up_form_amount').focus();
	var $bonuses = $form.find('table#bonuses tbody tr');
	var $submit = $form.find('button[type=submit]');
	var $method = $form.find('input[name=method]');
	var $taxLess = $form.find('div#taxLess');
	var $reward = $form.find('div#reward');
	var $total = $form.find('div#total');
	var $tax = $form.find('div#tax');


	$method.change(function() {
		$submit.text($submit.data(this.id));
	});

	function onAmountChange() {
		if ($(this).val() > 9) {
			$(this).val($(this).val().slice(0,9));
		}

		var amount = parseInt($(this).val());
		$reward.text('0 '+ currency);

		if (!amount) amount = 0;

		$total.text(amount.toFixed(2));
		let tax = amount * 21 / 121;
		$tax.text(Math.round(tax).toFixed(2));
		$taxLess.text(Math.round(amount - tax).toFixed(2));

		$bonuses.each(function() {
			var $check = $(this).find('td:first').empty();
			var range = $(this).find('td:eq(1)').text().split('-');

			if (amount >= parseInt(range[0]) && (range.length == 1 || amount <= parseInt(range[1]))) {
				$check.append('<i class="fa fa-check text-sm text-success"></i>');
				$reward.text($(this).find('td:last').text());
			}
		});
	}

	$amount.keyup(onAmountChange);
	$amount.change(onAmountChange);

}
