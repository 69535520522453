document.querySelectorAll(".company-user-toggle").forEach(function (e, i) {
    e.addEventListener("click", function () {
        this.closest('li.users-list-item').classList.toggle('toggle-content');
    });
});

let usersList = document.getElementById('tabsUsers'),
    updateUser = document.querySelectorAll('.update-company-user'),
    userDeleteModal = document.getElementById('userDeleteModal');


if ( usersList ) {
    let deleteLinks = document.querySelectorAll('a[data-toggle="modal"][class="icon-trash"]');

    if (userDeleteModal) {
        deleteLinks.forEach(function (item, key) {
            item.addEventListener('click', function (e) {
                userDeleteModal.querySelector('a[type="button"]').setAttribute('href', this.href)
            })
        })
    }
}

updateUser.forEach(function (u, i) {
    u.addEventListener("click", function () {
        let id = this.dataset.id;
        let data = {
            firstname: document.getElementById('firstName-'+id).value,
            lastname: document.getElementById('lastName-'+id).value,
            phone: document.getElementById('tel-'+id).value,
            id: id
        };

        fetch('/api/company-management/update-user', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(function(response) {
                status = response.status;
                return response.json();
            })
                .then(function(data) {
                    alert(data)
                });
    });
});
