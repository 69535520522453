let reviewsBlockEl = document.querySelector('ul.list-reviews-company');

if (reviewsBlockEl) {
	let detailGradesBlockEls = reviewsBlockEl.querySelectorAll('a.review-rating-detail');
	
	if (detailGradesBlockEls && detailGradesBlockEls.length > 0) {
		detailGradesBlockEls.forEach(function (item, key) {
			item.addEventListener('click', function (e) {
				
				let reviewCode = item.getAttribute('data-target-code'),
					gradesListBlock = document.querySelector('div[data-target="'+reviewCode+'"] > ul.about-company-characteristics');
				
				if(item.getAttribute('disabled') === true || item.querySelector('i.fa-spinner')) {
					item.setAttribute('disabled', false);
					return;
				}
				
				item.setAttribute('disabled', true);
				item.insertAdjacentHTML('afterbegin', '<i class="fas fa-spinner fa-spin"></i>');
				
				if(gradesListBlock.querySelectorAll('li').length <= 0) {
					fetch('/api/review/' + reviewCode + '/grades')
						.then(response => response.json())
						.then(function(data) {
							
							item.removeChild(item.firstChild);
							item.setAttribute('disabled', false);
							
							gradesListBlock.insertAdjacentHTML('beforeend', renderGrades(data));
							item.parentNode.parentNode.parentNode.classList.toggle('active-el');
						})
				} else {
					item.removeChild(item.firstChild);
					item.setAttribute('disabled', false);
					item.parentNode.parentNode.parentNode.classList.toggle('active-el');
				}
			})
		})
	}
}

function renderGrades(data) {
	
	let listHtml = '';
	
	data.forEach(function (value, index) {
		
		let grades = '';
		
		for (let i = 1; i <= 5; i++) {
			let isActive = i <= value.grade ? 'active-icon' : '';
			
			grades += '<li class="' + isActive + '"><i class="fas fa-star"></i></li>';
			
		}
		
		listHtml += '<li>' +
			'   <p>' + value.rating + ':</p>' +
			'   <div>' +
			'       <ul class="rating-list-icons">' +
						grades +
			'       </ul>' +
			'   </div>' +
			'</li>';
	});
	
	return listHtml;
}