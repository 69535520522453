<template>
	<div class="no-letters">
		<div class="no-letters-content">
			<p class="no-letters-content__p">
				{{ title }}
			</p>
			<a href="/jobs" class="no-letters-content__a">
				{{ labelForJobs }}
			</a>
			<a href="/cv" class="no-letters-content__a">
				{{ labelForCvs }}
			</a>
		</div>
		<img src="/images/no-letters-img.svg" alt="" class="no-letters__img">
	</div>
</template>

<script>
export default {

	name: 'SubscriptionsNoData',

	props: {
		title: String,
		labelForJobs: String,
		labelForCvs: String,
	},

	data () {
		return {

		}
	}
}
</script>

<style lang="css" scoped>
</style>