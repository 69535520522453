let customPackageForm = document.getElementById('custom-package-form');

function setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            } else {
                this.value = "";
            }
        });
    });
}

const counterFields = document.querySelectorAll('.counter__field');
const counterBtnsPlus = document.querySelectorAll('.counter__plus');
const counterBtnsMinus = document.querySelectorAll('.counter__minus');

if (counterFields) {
    counterFields.forEach(field => {
        setInputFilter(field, function (value) {
            return /^-?\d*$/.test(value);
        });

        field.addEventListener('input', () => {
            calculationTotalAmount();
        })
    });

    counterBtnsPlus.forEach(btn => {
        btn.addEventListener('click', () => {
            const parentBlock = btn.parentNode.parentNode.parentNode;
            let field = parentBlock.querySelector('.counter__field');
            field.value = (parseInt(field.value) + 1).toString();
            calculationTotalAmount();
            const serviceTotalBlock = parentBlock.querySelector('.service-total-block');
            serviceTotalBlock.classList.add('service-total-block__show');
        });
    })

    counterBtnsMinus.forEach(btn => {
        btn.addEventListener('click', () => {
            const parentBlock = btn.parentNode.parentNode.parentNode;
            let field = parentBlock.querySelector('.counter__field');
            if (parseInt(field.value) > 0) {
                field.value = (parseInt(field.value) - 1).toString();
                calculationTotalAmount();
            }
            if (parseInt(field.value) === 0) {
                const serviceTotalBlock = parentBlock.querySelector('.service-total-block');
                serviceTotalBlock.classList.remove('service-total-block__show');
            }
        });
    })

    function calculationTotalAmount() {
        const tabSumVal = document.querySelector('.tab-sum-val');
        let count = 0;

        counterFields.forEach(field => {
            const parentBlock = field.parentNode.parentNode.parentNode;
            const price = parentBlock.querySelector('.services-table-item__col__2').getAttribute('data-price');
            // const serviceTotalBlock = parentBlock.querySelector('.service-total-block');
            // serviceTotalBlock.classList.add('service-total-block__show');
            parentBlock.querySelector('.service-total-block__val').innerHTML = (parseInt(field.value) * price).toString();
            count += parseInt(field.value) * price;
        });

        tabSumVal.innerHTML = (count).toString();
    }
}

if (customPackageForm) {
    customPackageForm.addEventListener('submit', function (event) {

        let canSubmit = false;

        counterFields.forEach(function (field) {

            if (field.value > 0) {
                canSubmit = true;
            }
        });

        if (!canSubmit) {
            alert('Select at least one service');
            event.preventDefault();
        }
    })
}