var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showLoader
        ? _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticClass: "w-half",
              attrs: { src: "/images/gifs/wait.gif" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.jobs.items && !_vm.showLoader
        ? _c(
            "div",
            {
              ref: "stick",
              staticClass: "vacancy-management-block",
              class: [_vm.scrolled ? "vacancy-management-block-fixed" : ""],
            },
            [
              _c("div", { staticClass: "checkbox-vacancy" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectAll,
                      expression: "selectAll",
                    },
                  ],
                  attrs: { id: "select-all", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.selectAll)
                      ? _vm._i(_vm.selectAll, null) > -1
                      : _vm.selectAll,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.selectAll,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectAll = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectAll = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "select-all" } }, [
                  _vm._v(_vm._s(_vm.labelSelectAll)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "vacancy-management-block-buttons batch-actions",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "vacancy-management-block__btn vacancy-management-block__btn__update",
                      attrs: {
                        disabled: _vm.selectedJobs.length <= 0,
                        type: "button",
                      },
                      on: { click: _vm.batchDateRefresh },
                    },
                    [
                      _c("span", { staticClass: "material-icons" }, [
                        _vm._v("update"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-for-btn" }, [
                        _vm._v(_vm._s(_vm.labelRefreshDate)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "vacancy-management-block__btn vacancy-management-block__btn__delete",
                      attrs: {
                        disabled: _vm.selectedJobs.length <= 0,
                        type: "button",
                      },
                      on: { click: _vm.batchDelete },
                    },
                    [
                      _c("span", { staticClass: "material-icons" }, [
                        _vm._v("delete"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-for-btn" }, [
                        _vm._v(_vm._s(_vm.labelRemove)),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.jobs.items && !_vm.showLoader
        ? _c("div", { staticClass: "table-response-vacancy" }, [
            _c(
              "div",
              {
                staticClass:
                  "table-response-vacancy-header table-response-my-vacancy-header",
              },
              [
                _c("p", { staticClass: "col1" }, [
                  _vm._v(_vm._s(_vm.labelTitle)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "col2" }, [
                  _vm._v(_vm._s(_vm.labelStatus)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "col3" }, [
                  _vm._v(_vm._s(_vm.labelCreatedAt)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "col4" }, [_vm._v(" ")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "table-response-vacancy-body" },
              _vm._l(_vm.jobs.items, function (job) {
                return _c(
                  "li",
                  {
                    staticClass:
                      "table-response-vacancy-row-small table-response-vacancy-row",
                    class: [
                      job.promotedByColor ? "paid-tariff" : "",
                      job.promotedByBadge ? "vip-paid-tariff" : "",
                      _vm.selectedJobs.includes(job.shortId)
                        ? "border-success"
                        : "",
                    ],
                  },
                  [
                    _c("i", { staticClass: "fas fa-medal icon-vip-paid" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col1" }, [
                      _c("div", { staticClass: "checkbox-vacancy" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedJobs,
                              expression: "selectedJobs",
                            },
                          ],
                          attrs: { id: job.id, type: "checkbox" },
                          domProps: {
                            value: job.shortId,
                            checked: Array.isArray(_vm.selectedJobs)
                              ? _vm._i(_vm.selectedJobs, job.shortId) > -1
                              : _vm.selectedJobs,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedJobs,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = job.shortId,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedJobs = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedJobs = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedJobs = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: job.id } }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.getUrl(job.title, job.shortId),
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(job.title) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          job.isArchived
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "ml-1 font-weight-light badge badge-warning",
                                  attrs: {
                                    "data-placement": "bottom",
                                    "data-toggle": "tooltip",
                                    title: _vm.labelArchivedTooltip,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.labelArchived) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col2" }, [
                      job.isPublished
                        ? _c("p", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "rounded text-success border border-success px-2 py-1",
                              },
                              [_vm._v(_vm._s(_vm.labelPublished))]
                            ),
                            _vm._v(" "),
                            _c("i", { staticClass: "fas fa-check-circle" }),
                          ])
                        : _c("p", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "rounded text-danger tooltips border border-danger px-2 py-1",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.labelNotPublished) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", { staticClass: "fas fa-eye-slash" }),
                          ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "col3" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.formatDate(job.publishedAt)) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn-vacancy-toggle",
                          attrs: {
                            "aria-expanded": "false",
                            "aria-haspopup": "true",
                            "data-toggle": "dropdown",
                            type: "button",
                            id: "dropdownMenuButton",
                          },
                        },
                        [
                          _c("div"),
                          _vm._v(" "),
                          _c("div"),
                          _vm._v(" "),
                          _c("div"),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-menu dropdown-menu-right vacancy-list",
                          attrs: { "aria-labelledby": "dropdownMenuButton" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                "data-action": "refreshDate",
                                "data-id": job.id,
                                href: _vm.getUrl(
                                  job.title,
                                  job.shortId,
                                  "refresh"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labelRefreshDate) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                href: _vm.getUrl(
                                  job.title,
                                  job.shortId,
                                  "edit"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labelEdit) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                target: "_blank",
                                href: _vm.getUrl(
                                  job.title,
                                  job.shortId,
                                  "preview"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labelPreview) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                onclick: "return confirm(labelConfirmRemove)",
                                href: _vm.getUrl(
                                  job.title,
                                  job.shortId,
                                  "remove"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.labelRemove) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.jobs.items && _vm.jobs.pagination.pageCount > 1
        ? _c("Pagination", {
            attrs: { "pagination-data": _vm.jobs.pagination },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }