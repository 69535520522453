<template>
	<div>
		<label class="uploader vue-uploader" v-bind:class="[imageSrc !== '' ? 'loaded' : '']">
			<i class="far fa-image"></i>
			<i class="icon-upload icon"></i>
			<img :src="imageSrc" v-bind:class="[imageSrc !== '' ? 'loaded' : '']">
			<input type="file" name="attachments[file]" accept="image/*" @change="onChange" v-bind:disabled="imageSrc !== ''">
			<button type="button" class="btn-remove-img" @click="onResetImage">✖</button>
		</label>
	</div>
</template>

<script>
	import * as axios from "axios";
	
	export default {
		name: "Gallery",
		data() {
			return {
				imageSrc: '',
				filename: this.nodeIndex
			}
		},
		props: {
			nodeIndex: String,
			attached: String,
			cv: String,
		},
		mounted() {
			this.imageSrc = this.$props.attached ? this.$props.attached : '';
		},
		methods: {
			onChange: function (event) {
				let self = this;
				
				let files = event.target.files || event.dataTransfer.files;
				
				if (this.target || this.target !== '') {
					let file = event.target.files[0] || event.dataTransfer.files[0],
						formData = new FormData;
					
					formData.append('offer', self.cv);
					formData.append('gallery[]', file);
					
					axios.post('/api/attachments/upload', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(function (response) {
						/*self.filename = response['gallery'][0];
						self.nodeIndex = response['gallery'][0];*/
						
						self.filename = response.data;
						event.target.disabled = true;
					}).catch(function (error) {
						
						});
					
					self.createImage(files[0]);
				}
			},
			createImage(file) {
				let image = new Image(),
					reader = new FileReader(),
					vm = this;
				
				reader.onload = (e) => {
					vm.imageSrc = e.target.result;
				};
				reader.readAsDataURL(file);
			},
			onResetImage: function (event) {
				let self = this,
					postData = {
						'file': self.filename,
						'offer': self.cv
					};
				
				axios.post('/api/attachments/remove', postData).then(function(response) {
					self.imageSrc = '';
					self.filename = '';
					event.target.disabled = false;
				}).catch(function (response) {
				
				});
			}
			
		}
	}
</script>

<style scoped>

</style>