const btnOpenLoginReg = document.querySelector(".btn-login-reg");
const loginRegBlock = document.querySelector(".login-reg-block");
const btnsLoginReg = document.querySelectorAll(".login-reg-block .login-reg-block-header-item");
const contentLoginReg = document.querySelectorAll(".login-reg-block .login-reg-block-content");
let openLoginRegBlock = false;

const loginRegRadioBtn = document.querySelectorAll(".login-reg-block .radio-button-input");

if (btnOpenLoginReg) {
    btnOpenLoginReg.addEventListener('click', () => {
        if (openLoginRegBlock) {
            loginRegBlock.classList.remove('login-reg-block__open');
        } else {
            loginRegBlock.classList.add('login-reg-block__open');

            const navbarToggler = document.querySelector('.navbar-toggler');
            const navbarCollapse = document.querySelector('.navbar-collapse');
            if (navbarCollapse.classList.contains('show')) {
                const eventClick = new Event('click', {bubbles: true});
                navbarToggler.dispatchEvent(eventClick);
            }
        }
        openLoginRegBlock = !openLoginRegBlock;
    });

    document.addEventListener('mousedown', (e) => {
        openLoginRegBlock && onClickChech(e, '.login-reg-block', '.btn-login-reg', btnOpenLoginReg);
    });

    const btnsDropdown = document.querySelectorAll('.dropdown-toggle');
    btnsDropdown.forEach(btn => {
        btn.addEventListener('click', (e) => {
            const eventClick = new Event('click', {bubbles: true});
            if (openLoginRegBlock) {
                btnOpenLoginReg.dispatchEvent(eventClick);
            }
        });
    });

    btnsLoginReg.forEach(btn => {
        btn.addEventListener('click', (e) => {
            const dataTab = btn.getAttribute("data-login-reg-content");

            btnsLoginReg.forEach(b => {
                b.classList.remove('active');
            });
            btn.classList.add('active');

            contentLoginReg.forEach(block => {
                if (block.classList.contains(dataTab)) {
                    block.classList.add('login-reg-block-content__active');
                } else {
                    block.classList.remove('login-reg-block-content__active');
                }
            });
        });
    });

    loginRegRadioBtn.forEach(radioBtn => {
        radioBtn.addEventListener('click', () => {
            const regSocialWrap = radioBtn.parentNode.parentNode.parentNode.parentNode.querySelector(".reg-social-wrap");
            const fieldsForHide = radioBtn.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".login-reg-form-row__hide");

            if (loginRegRadioBtn[1].checked) {
                regSocialWrap.classList.remove('d-none');
                if(fieldsForHide.length > 0) {
                    fieldsForHide.forEach(field => {
                        field.classList.add('d-none');
                    })
                }
            } else {
                regSocialWrap.classList.add('d-none');
                if(fieldsForHide.length > 0) {
                    fieldsForHide.forEach(field => {
                        field.classList.remove('d-none');
                    })
                }
            }
        });
    });
}

function onClickChech(e, blockTag, btnTag, btnOpenLoginReg) {
    e = e || window.event;
    const eventClick = new Event('click', {bubbles: true});
    const block = document.querySelector(blockTag);
    const btn = document.querySelector(btnTag);

    let target = e.target || e.srcElement;

    while (target.parentNode && target.tagName.toLowerCase() !== 'body') {
        if (target === block || target === btn) {
            return false;
        }
        target = target.parentNode;
    }

    btnOpenLoginReg.dispatchEvent(eventClick);
}