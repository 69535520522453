// let color3ListItem = document.querySelector('.services-list .color3 li:nth-child(5) p');
//
// if (color3ListItem) {
// 	let icon = document.createElement("i");
// 	icon.className = "fas fa-medal";
// 	color3ListItem.appendChild(icon);
// }


let blocksMedal = document.querySelectorAll('.services-list .add-medal');

if (blocksMedal) {
    blocksMedal.forEach(block => {
        let icon = document.createElement("i");
        icon.className = "fas fa-medal";
        block.appendChild(icon);
    })
}

