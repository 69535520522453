import * as axios from 'axios';

let localeSwitcherEl = document.getElementById('locales-list');

if(localeSwitcherEl) {
	localeSwitcherEl.querySelectorAll('a.locale-list-item').forEach(function(link) {
		link.addEventListener('click', function(event) {
			
			event.preventDefault();
			
			let href = event.target.getAttribute('data-href'),
				locale = event.target.getAttribute('data-locale');
			
			axios
				.get('/api/switch-locale/' + locale)
				.then(function(response) {
					window.location.href = href;
				});
		})
	})
}