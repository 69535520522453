/**
 * @param element
 */
export function init(element) {
	
	fetch('/api/professions.json?lang=' + document.documentElement.lang)
		.then(response => response.json())
		.then(function(data) {
			autocomplete(element, data)
		});
	
}

/**
 * @see https://www.w3schools.com/howto/howto_js_autocomplete.asp
 *
 * @param inp
 * @param closeAllLists
 * @param currentFocus
 * @param arr
 * @returns {*}
 */
function inputEvent(inp, closeAllLists, currentFocus, arr) {
	inp.addEventListener("input", function (e) {
		let spinner = document.querySelector('.fas.fa-spinner.autocomplete-spinner');
		
		if(!spinner) {
			
			let spinner = document.createElement('i');
			spinner.classList.add('fas', 'fa-spinner', 'fa-spin', 'autocomplete-spinner');
			
			e.target.parentNode.appendChild(spinner);
		} else {
			spinner.classList.remove('d-none');
		}
		
		setTimeout(function(element, spinner){
		
			if(spinner) {
				spinner.classList.add('d-none');
			}
			
			var a, b, c, i, val = element.value, isShowAll = false;
			/*close any already open lists of autocompleted values*/
			closeAllLists();
			if (!val) {
				return false;
			}
			currentFocus = -1;
			/*create a DIV element that will contain the items (values):*/
			a = document.createElement("DIV");
			a.setAttribute("id", this.id + "autocomplete-list");
			a.setAttribute("class", "autocomplete-items");
			/*append the DIV element as a child of the autocomplete container:*/
			
			element.parentNode.appendChild(a);
			/*for each item in the array...*/

			Object.keys(arr).forEach(key => {
				let substrIndex = arr[key].toUpperCase().search(val.toUpperCase());
				
				if (substrIndex >= 0) {
					b = document.createElement("DIV");
					b.innerHTML = arr[key].substr(0, substrIndex);
					b.innerHTML += "<strong>" + arr[key].substr(substrIndex, element.length) + "</strong>";
					// b.innerHTML += arr[key].substr(substrIndex + element.length);
					
					/*insert a input field that will hold the current array item's value:*/
					b.innerHTML += "<input type='hidden' value='" + arr[key] + "'>";
					/*b.innerHTML += `<div class='applicant-form-info'>
										<p><i class="fas fa-briefcase"></i>Workintense</p>
										<p><i class="fas fa-map-marker-alt"></i>Прага</p>
										<p><i class="fas fa-eye"></i>15</p>
									</div>`;*/
					
					/*execute a function when someone clicks on the item value (DIV element):*/
					b.addEventListener("click", function (e) {
						/*insert the value for the autocomplete text field:*/
						element.value = this.getElementsByTagName("input")[0].value;
						/*close the list of autocompleted values,
						(or any other open lists of autocompleted values:*/
						closeAllLists();
					});
					a.appendChild(b);
					isShowAll = true;
				}
			})
			const field = inp.querySelector('.form-control');
			if(isShowAll && field.getAttribute('data-isShowAll')) {
				const oldTag = document.querySelector('.show-all-jobs__template');
				const newtag = oldTag.cloneNode(true);
				newtag.classList.remove('d-none');
				a.appendChild(newtag);

				let btn = document.querySelector('.autocomplete-items .show-all-jobs');
				btn.addEventListener('click', function () {
					let btnSendForm = document.querySelector('.jumbotron-form-btn-icon');
					closeAllLists();
					btnSendForm.click();
				})
			}
		},2000, e.target, spinner);

	});
	return currentFocus;
}

export function autocomplete(inp, arr) {
	/*the autocomplete function takes two arguments,
	the text field element and an array of possible autocompleted values:*/
	var currentFocus;
	/*execute a function when someone writes in the text field:*/
	
	currentFocus = inputEvent(inp, closeAllLists, currentFocus, arr);
	
	/*execute a function presses a key on the keyboard:*/
	inp.addEventListener("keydown", function(e) {
		var x = document.getElementById(this.id + "autocomplete-list");
		if (x) x = x.getElementsByTagName("div");
		if (e.keyCode == 40) {
			/*If the arrow DOWN key is pressed,
			increase the currentFocus variable:*/
			currentFocus++;
			/*and and make the current item more visible:*/
			addActive(x);
		} else if (e.keyCode == 38) { //up
			/*If the arrow UP key is pressed,
			decrease the currentFocus variable:*/
			currentFocus--;
			/*and and make the current item more visible:*/
			addActive(x);
		} else if (e.keyCode == 13) {
			/*If the ENTER key is pressed, prevent the form from being submitted,*/
			e.preventDefault();
			if (currentFocus > -1) {
				/*and simulate a click on the "active" item:*/
				if (x) x[currentFocus].click();
			}
		}
	});
	function addActive(x) {
		/*a function to classify an item as "active":*/
		if (!x) return false;
		/*start by removing the "active" class on all items:*/
		removeActive(x);
		if (currentFocus >= x.length) currentFocus = 0;
		if (currentFocus < 0) currentFocus = (x.length - 1);
		/*add class "autocomplete-active":*/
		x[currentFocus].classList.add("autocomplete-active");
	}
	function removeActive(x) {
		/*a function to remove the "active" class from all autocomplete items:*/
		for (var i = 0; i < x.length; i++) {
			x[i].classList.remove("autocomplete-active");
		}
	}
	function closeAllLists(elmnt) {
		/*close all autocomplete lists in the document,
		except the one passed as an argument:*/
		var x = document.getElementsByClassName("autocomplete-items");
		for (var i = 0; i < x.length; i++) {
			if (elmnt != x[i] && elmnt != inp) {
				x[i].parentNode.removeChild(x[i]);
			}
		}
	}
	/*execute a function when someone clicks in the document:*/
	document.addEventListener("click", function (e) {
		closeAllLists(e.target);
	});
}