var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-list-wrap" }, [
    _c(
      "div",
      { staticClass: "toggle-comments mr-4", on: { click: _vm.list } },
      [
        _vm.collapsed
          ? _c("span", { staticClass: "show-comments" }, [
              _vm._v(
                _vm._s(this.showCommentsTitle) +
                  ": " +
                  _vm._s(_vm.comments.length)
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.collapsed
          ? _c("span", { staticClass: "show-comments" }, [
              _vm._v(_vm._s(this.hideCommentsTitle)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("i", { staticClass: "fas fa-reply toggle-comments-icon" }),
      ]
    ),
    _vm._v(" "),
    !_vm.collapsed
      ? _c(
          "div",
          { staticClass: "comment-card-wrap" },
          [
            _vm._l(_vm.comments, function (comment) {
              return _vm.loaded && !_vm.failed && !_vm.pending
                ? _c("div", { key: comment.id }, [
                    _c("div", { staticClass: "comment-card-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "align-items-center d-flex justify-content-between py-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "align-items-center d-flex " },
                            [
                              comment.isRelatedEmployer
                                ? _c(
                                    "div",
                                    { staticClass: "company-main-icon reply" },
                                    [_c("i", { staticClass: "far fa-user" })]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "company-main-icon reply" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "far fa-user rounded-circle",
                                      }),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("div", { staticClass: "comment-autor-name" }, [
                                _vm._v(_vm._s(comment.name)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "review-content" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(comment.text) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.loaded &&
            !_vm.pending &&
            !_vm.failed &&
            _vm.comments.length <= 0
              ? _c("div", {}, [
                  _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "align-items-center d-flex justify-content-center no-comments-title",
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.noCommentsTitle))])]
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }