<template>
	<a
			aria-controls="showReplyForm"
			aria-expanded="false"
			class="btn btn-success btn-mr btn-sm"
			data-position="top"
			data-toggle="collapse" href="#showReplyForm" role="button"
			v-on:click="linkClicked"
	>{{ label }}</a>
</template>

<script>
	export default {
		name: "ReplyToggle",
		props: [
			'label'
		],
		data() {
			return {
				toggle: true,
			}
		},
		methods: {
			linkClicked: function () {
				let self = this;
				
				this.$root.$emit('replyToggle', !self.toggle);
				self.toggle = !self.toggle;
			}
		}
	}
</script>

<style scoped>

</style>