require('./updatePassword');
require('./showPassword');
require('./uploadPhoto');
require('./contactBy');
require('./CompanyForm');
require('./usersList');
require('./requestsList');
require('./CompanyFormValidation');
require('./custom-select');
require('./reloadPage');
require('./removePhoto');
require('./inputShowHidePass');
require('./custom-control');
require('./accountEmployersNav');
