const
	flatpickr = require('flatpickr/dist/flatpickr.min'),
	monthSelectPlugin = require('flatpickr/dist/plugins/monthSelect');

let formEl = document.querySelector('[name="manage_review"]');

if(formEl) {
	let dateMonthEls = formEl.querySelectorAll('[data-type="select-month"]');

	dateMonthEls.forEach(function(item) {
		flatpickr(item, {
			plugins: [
				new monthSelectPlugin({
					// shorthand: true, //defaults to false
					dateFormat: "m.Y", //defaults to "F Y"
					// altFormat: "F Y", //defaults to "F Y"
				})
			]
		})
	})
}