var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showForm
      ? _c("div", [
          _c(
            "p",
            {
              staticClass: "new-job-add-block-wrap-title",
              attrs: { id: "blockAttachedFiles" },
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(_vm.attachmentsTitle) + "\n\t\t\t")]
          ),
          _vm._v(" "),
          _c("form", { attrs: { novalidate: "" } }, [
            _c("div", { staticClass: "form-group " }, [
              _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
                _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                  _c(
                    "p",
                    {
                      staticClass: "form-field-title d-flex align-items-center",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.uploadsDocLabel) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "new-job-add-block-wrap-row-right" },
                  [
                    _vm._l(_vm.docs, function (doc, name) {
                      return _c("div", { staticClass: "my-2" }, [
                        _c("a", { attrs: { target: "_blank", href: doc } }, [
                          _vm._v(
                            " " + _vm._s(decodeURI(name).slice(0, 50)) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "ml-5 text-danger",
                            on: {
                              click: function ($event) {
                                return _vm.onDocReset(name)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-trash" }, [
                              _vm._v(" "),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    Object.entries(_vm.docs).length < 5
                      ? _c("div", { staticClass: "field-add-file" }, [
                          _c(
                            "div",
                            {
                              class: [
                                _vm.isWrongSize || _vm.isWrongFormat
                                  ? "is-invalid-block"
                                  : "",
                              ],
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.fileName,
                                    expression: "fileName",
                                  },
                                ],
                                staticClass: "f-input form-control",
                                class: [
                                  _vm.isWrongSize || _vm.isWrongFormat
                                    ? "is-invalid"
                                    : "",
                                ],
                                attrs: {
                                  disabled: "",
                                  placeholder: _vm.uploadsDocPlaceholder,
                                },
                                domProps: { value: _vm.fileName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.fileName = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "fileUpload btn btn--browse" },
                                [
                                  _c("input", {
                                    staticClass: "upload",
                                    attrs: {
                                      type: "file",
                                      name: "cv_form[file]",
                                    },
                                    on: { change: _vm.onFileChange },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "text-muted form-text help-text",
                              attrs: { id: "cv_form_file_help" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  class: [
                                    _vm.isWrongSize
                                      ? "text-danger font-weight-bolder"
                                      : "",
                                  ],
                                },
                                [_vm._v("Maximum file size: 4 MB")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  class: [
                                    _vm.isWrongFormat
                                      ? "text-danger font-weight-bolder"
                                      : "",
                                  ],
                                },
                                [_vm._v("Allowed file formats: doc, ODT, xls")]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-3" }, [
              _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
                _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "form-field-title d-flex align-items-center mb-3 uploads-gallery-block",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.uploadsGalleryLabel) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "new-job-add-block-wrap-row-right block-downloaded-files",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "list-downloaded-files" },
                      _vm._l(_vm.imageFiles, function (image, index) {
                        return _c("gallery", {
                          key: index,
                          attrs: {
                            attached: image,
                            "node-index": String(index),
                            cv: _vm.cv,
                          },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "help-text",
                        attrs: { id: "cv_form_photo_help" },
                      },
                      [_vm._v("Max. 8 MB. Formats: jpg, jpeg, png, gif")]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.showForm
      ? _c("div", { staticClass: "form-group mb-0" }, [
          _c(
            "div",
            {
              staticClass:
                "new-job-add-block-wrap-row new-job-add-block-wrap-row__flex",
            },
            [
              _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                !_vm.showForm
                  ? _c(
                      "p",
                      {
                        staticClass: "additional-information-block-title",
                        attrs: { disabled: !_vm.cv },
                      },
                      [
                        !_vm.showLoader
                          ? _c("span", [_vm._v(_vm._s(_vm.uploadsAddTitle))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showLoader
                          ? _c("i", {
                              staticClass: "fa fa-fw fa-spin fa-spinner",
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "new-job-add-block-wrap-row-right text-right" },
                [
                  !_vm.showForm
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-show-additional-information-block",
                          attrs: { type: "button", disabled: !_vm.cv },
                          on: { click: _vm.onToggleForm },
                        },
                        [_c("i", { staticClass: "fas fa-chevron-down" })]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }