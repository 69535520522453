const btnsAcceptApplication = document.querySelectorAll(".accept-application");
const btnsRejectApplication = document.querySelectorAll(".reject-application");

if (btnsAcceptApplication) {
    btnsAcceptApplication.forEach(function (btn, i) {
        btn.addEventListener("click", function () {
            let req = this.dataset.request;
            let data = {
                status: 'accept',
                id: req
            };
            fetch('/api/company-management/requests/manage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(function(response) {

                    let status = response.status;
                    if (status == 200) {
                        let acceptedBlock = document.getElementById('block-msg-accepted-'+req);
                        let declinedBlock = document.getElementById('block-msg-declined-'+req);
                        let buttonsBlock = document.getElementById('block-btns-'+req);
                        acceptedBlock.classList.remove('d-none');
                        declinedBlock.classList.add('d-none');
                        buttonsBlock.classList.add('d-none');
                    }

                    return response.json();
                })
                .then(function(data) {
                    alert(data)
                });
        })

    });
}

if (btnsRejectApplication) {
    btnsRejectApplication.forEach(function (btn, i) {
        btn.addEventListener("click", function () {
            let req = this.dataset.request;
            let data = {
                status: 'decline',
                id: req
            };
            fetch('/api/company-management/requests/manage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(function(response) {

                    let status = response.status;
                    if (status == 200) {
                        let acceptedBlock = document.getElementById('block-msg-accepted-'+req);
                        let declinedBlock = document.getElementById('block-msg-declined-'+req);
                        let buttonsBlock = document.getElementById('block-btns-'+req);
                        acceptedBlock.classList.add('d-none');
                        declinedBlock.classList.remove('d-none');
                        buttonsBlock.classList.add('d-none');
                    }

                    return response.json();
                })
                .then(function(data) {
                    alert(data)
                });
        })

    });
}