var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reviews-footer-bottom pt-4 px-4" }, [
    !this.sent && !this.pending
      ? _c("div", [
          _c("form", { attrs: { name: "leave_comment" } }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "text-muted", attrs: { for: "name" } },
                [_vm._v(_vm._s(this.nameLabel))]
              ),
              _c("span", { staticClass: "text-danger mx-1" }, [_vm._v("*")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass: "form-control",
                class: [_vm.errors.name ? "border-danger" : ""],
                attrs: { id: "name", placeholder: this.namePlaceholder },
                domProps: { value: _vm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "text-muted", attrs: { for: "name" } },
                [_vm._v(_vm._s(this.commentLabel))]
              ),
              _c("span", { staticClass: "text-danger mx-1" }, [_vm._v("*")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.comment,
                    expression: "comment",
                  },
                ],
                staticClass: "mb-0",
                class: [_vm.errors.comment ? "border-danger" : ""],
                attrs: { placeholder: this.commentPlaceholder },
                domProps: { value: _vm.comment },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.comment = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.relatedUser.length <= 0
              ? _c(
                  "div",
                  { staticClass: "custom-control custom-checkbox form-group" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.anonymously,
                          expression: "anonymously",
                        },
                      ],
                      staticClass: "custom-control-input text-body",
                      attrs: { type: "checkbox", id: "anonymously" },
                      domProps: {
                        checked: Array.isArray(_vm.anonymously)
                          ? _vm._i(_vm.anonymously, null) > -1
                          : _vm.anonymously,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.anonymously,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.anonymously = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.anonymously = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.anonymously = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "anonymously" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(this.anonymouslyLabel) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-success mr-3 mb-3",
                  attrs: { type: "button" },
                  on: { click: _vm.handleSubmit },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(this.submitTitle) + "\n\t\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary mb-3",
                  attrs: { type: "button" },
                  on: { click: _vm.handleCancelClick },
                },
                [_vm._v(_vm._s(this.cancelTitle))]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "review-footer" }, [
              _vm._v(
                "Отзыв публикуется на сайте только после проверки модератором."
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.pending
      ? _c("div", { staticClass: "text-center py-5 " }, [
          _c("i", { staticClass: "fa-3x fa-spin fa-spinner fas text-primary" }),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.sent && !this.failed
      ? _c("div", { staticClass: "text-center py-5 " }, [
          _c("i", { staticClass: "fas fa-check fa-3x text-success" }),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.failed && this.sent && !this.pending
      ? _c("div", { staticClass: "text-center py-5 " }, [
          _c("i", {
            staticClass: "fas fa-exclamation-circle fa-3x text-danger",
          }),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass: "m-0 mt-2 text-info text-underline",
                on: { click: _vm.retry },
              },
              [_vm._v(_vm._s(this.retryTitle))]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }