let mySubscriptionsEl = document.getElementsByClassName('block-response-vacancy'),
	unsubscribeConfirmModelEl = document.getElementById('subscriptionsList');

if(mySubscriptionsEl && mySubscriptionsEl.length > 0) {
	let deleteLinks = document.querySelectorAll('a[data-toggle="modal"][class="dropdown-item"]');
	
	if(unsubscribeConfirmModelEl) {
		deleteLinks.forEach(function (item, key) {
			item.addEventListener('click', function (e) {
				unsubscribeConfirmModelEl.querySelector('a[type="button"]').setAttribute('href', e.target.getAttribute('href'))
			})
		})
	}

}