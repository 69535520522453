var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      {
        staticClass: "uploader vue-uploader",
        class: [_vm.imageSrc !== "" ? "loaded" : ""],
      },
      [
        _c("i", { staticClass: "far fa-image" }),
        _vm._v(" "),
        _c("i", { staticClass: "icon-upload icon" }),
        _vm._v(" "),
        _c("img", {
          class: [_vm.imageSrc !== "" ? "loaded" : ""],
          attrs: { src: _vm.imageSrc },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "file",
            name: "attachments[file]",
            accept: "image/*",
            disabled: _vm.imageSrc !== "",
          },
          on: { change: _vm.onChange },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-remove-img",
            attrs: { type: "button" },
            on: { click: _vm.onResetImage },
          },
          [_vm._v("✖")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }