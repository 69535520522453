const btnsPaymentMethod = document.querySelectorAll(".payment-method-list .castome-radio-btn");

function togglePaymentMethod(btn) {
	if (btn.checked) {
		btnsPaymentMethod.forEach(btnRadio => {
			btnRadio.parentNode.classList.remove('payment-method-active');
		});
		btn.parentNode.classList.add('payment-method-active');
	}
}

if (btnsPaymentMethod) {
	btnsPaymentMethod.forEach(btn => {
		
		togglePaymentMethod(btn);
		
		btn.addEventListener('change', function () {
			togglePaymentMethod(btn);
		});
		
	});
}