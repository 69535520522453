let btnEditContacts = document.querySelector(".btn-edit-contacts");

if (btnEditContacts) {
	let isEdit = false;
	let fieldsVal = [];

	btnEditContacts.addEventListener('click', () => {
		const parent = btnEditContacts.parentNode.parentNode;
		btnEditContacts.classList.toggle('btn-edit-contacts__is-edit');
		const fields = parent.querySelectorAll('input');

		if(!isEdit){
			fields.forEach(field => {
				field.disabled = false;
				fieldsVal.push(field.value);
			})
		} else {
			fields.forEach((field, i) => {
				field.disabled = true;
				field.value = fieldsVal[i];
			})
		}

		isEdit = !isEdit;
	});
}
