<template>
	<div>
		<div v-if="showForm">
			<p id="blockAttachedFiles" class="new-job-add-block-wrap-title">
				{{ attachmentsTitle }}
			</p>
			<form novalidate>
				<div class="form-group ">
					<div class="new-job-add-block-wrap-row">
						<div class="new-job-add-block-wrap-row-left">
							<p class="form-field-title d-flex align-items-center">
								{{ uploadsDocLabel }}
							</p>
						</div>
						<div class="new-job-add-block-wrap-row-right">
							<div v-for="(doc, name) in docs" class="my-2">
								<a target="_blank" v-bind:href="doc"> {{ decodeURI(name).slice(0, 50) }} </a>
								<a class="ml-5 text-danger" @click="onDocReset(name)"><i class="fas fa-trash">&nbsp;</i></a>
							</div>
							
							<div class="field-add-file" v-if="Object.entries(docs).length < 5">
								<div v-bind:class="[isWrongSize || isWrongFormat ? 'is-invalid-block' : '']">
									<input disabled :placeholder="uploadsDocPlaceholder" class="f-input form-control"
									       v-model="fileName"
									       v-bind:class="[isWrongSize || isWrongFormat ? 'is-invalid' : '']">
									<div class="fileUpload btn btn--browse">
										<input type="file" name="cv_form[file]" class="upload" @change="onFileChange">
									</div>
									<!--<button type="button" class="btn-delete-file"
									        v-bind:class="[!this.fileName ? 'd-none' : '']" @click="onDocReset">
										<i class="fas fa-times"></i>
									</button>-->
								</div>
								<p id="cv_form_file_help" class="text-muted form-text help-text">
									<span v-bind:class="[isWrongSize ? 'text-danger font-weight-bolder' : '']">Maximum file size: 4 MB</span>
									<span v-bind:class="[isWrongFormat ? 'text-danger font-weight-bolder' : '']">Allowed file formats: doc, ODT, xls</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group mb-3">
					<div class="new-job-add-block-wrap-row">
						<div class="new-job-add-block-wrap-row-left">
							<p class="form-field-title d-flex align-items-center mb-3 uploads-gallery-block">
								{{ uploadsGalleryLabel }}
							</p>
						</div>
						<div class="new-job-add-block-wrap-row-right block-downloaded-files">
							<div class="list-downloaded-files">
								
								<gallery v-for="(image, index) in imageFiles" :key="index"
								         v-bind:attached="image" v-bind:node-index="String(index)" v-bind:cv="cv"/>
							
							</div>
							<p id="cv_form_photo_help" class="help-text">Max. 8 MB. Formats: jpg, jpeg, png, gif</p>
						</div>
					</div>
				</div>
				<!--<div class="form-group">
					<div class="new-job-add-block-wrap-row">
						<div class="new-job-add-block-wrap-row-left"></div>
						<div class="new-job-add-block-wrap-row-right">
							<button class="btn btn-outline-success" @click="onSaveClick" type="button">
								<span v-if="savedMainInfo"><i class="fas fa-check"></i> </span>
								<span v-else><i class="fas fa-save"></i> {{ saveTitle }}</span>
							</button>
						</div>
					</div>
				</div>-->
			</form>
		</div>
<!--		<div class="text-center">-->
<!--			<button class="btn btn-outline-primary" type="button" v-on:click="onToggleForm" v-if="!showForm"-->
<!--			        :disabled="!cv">-->
<!--				{{ uploadsAddTitle }}-->
<!--			</button>-->
<!--		</div>-->

		<div class="form-group mb-0" v-if="!showForm">
			<div class="new-job-add-block-wrap-row new-job-add-block-wrap-row__flex">
				<div class="new-job-add-block-wrap-row-left">
					<p class="additional-information-block-title" v-if="!showForm":disabled="!cv">
						<span v-if="!showLoader">{{ uploadsAddTitle }}</span>
						<i v-if="showLoader" class="fa fa-fw fa-spin fa-spinner"></i>
					</p>
				</div>
				<div class="new-job-add-block-wrap-row-right text-right">
					<button class="btn-show-additional-information-block" type="button" v-on:click="onToggleForm" v-if="!showForm"
							:disabled="!cv">
						<i class="fas fa-chevron-down"></i>
					</button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import * as axios from "axios";
	import Gallery from "./Gallery";
	
	export default {
		name: "Attachments",
		components: {
			Gallery
		},
		data() {
			return {
				file: null,
				fileName: '',
				allowedFiles: [
					'application/pdf',
					'application/x-pdf',
					'application/msword',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				],
				isWrongFormat: false,
				isWrongSize: false,
				galleryCounter: 5,
				gallery: [],
				formData: null,
				isCanSave: false,
				savedMainInfo: false,
				showForm: false,
				imageFiles: [],
				docs: [],
				showLoader: false,
			}
		},
		props: {
			cv: String,
			saveTitle: String,
			attachmentsTitle: String,
			uploadsDocLabel: String,
			uploadsGalleryLabel: String,
			uploadsDocPlaceholder: String,
			uploadsAddTitle: String,
		},
		created() {
			this.formData = new FormData;
		},
		mounted() {
			let self = this;
			
			self.imageFiles = Array.apply(null, Array(self.galleryCounter)).map(function () {
				return null;
			});
			
			if (this.cv) {
				axios.get('/api/cv/' + this.cv + '/attachments')
					.then(function (response) {
						if (response.data) {
							
							self.imageFiles = response.data;
							
							self.showForm = true;
						}
					});
				
				self.getUploadedDocs();
			}
			
		},
		methods: {
			getUploadedDocs: function () {
				let self = this;
				
				axios.post('/api/attachments/docs', {
					offer: self.cv,
				}).then(function (response) {
					
					self.docs = response.data;
					
				}).catch(function (response) {
					console.log(response.statusCode);
				})
			},
			onFileChange: function (event) {
				let self = this,
					files = event.target.files || event.dataTransfer.files;
				
				if (this.docFileValidate(files)) {
					// this.fileName = files[0].name;
					this.file = files[0];
					
					let formData = new FormData;
					
					formData.append('offer', self.cv);
					formData.append('doc', files[0]);
					
					axios.post('/api/attachments/upload/doc', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(function (response) {
						self.savedMainInfo = true;
						
						setTimeout(function () {
							self.savedMainInfo = false;
						}, 2500);
						
						self.getUploadedDocs();
						self.file = '';
						self.fileName = '';
					})
						.catch(function () {
						
						});
					
				} else {
					this.fileName = '';
				}
			},
			docFileValidate: function (files) {
				this.isWrongFormat = this.isWrongSize = false;
				
				if (!files.length) {
					console.log('No files found');
					return false;
				}
				
				if (!this.allowedFiles.includes(files[0].type)) {
					this.isWrongFormat = true;
				}
				
				if ((files[0].size / 1000 / 1000) > 4) {
					this.isWrongSize = true;
				}
				
				return !this.isWrongFormat && !this.isWrongSize;
				
			},
			onGalleryChange: function (event, index) {
				console.log('event onGalleryChange was triggered');
			},
			onResetImage: function (index) {
				console.log('event onGalleryChange was triggered');
			},
			/*onSaveClick: function (event) {
				let self = this;
				
				if (self.gallery.length <= 0) {
					console.log('No gallery was found');
					
					if (self.attachments.length > 0) {
						self.attachments.forEach(function (item, index) {
							self.formData.append('gallery[' + index + ']', item);
						})
					} else {
						return false;
					}
				} else {
					self.gallery.forEach(function (item, index) {
						self.formData.append('gallery[' + index + ']', item);
					});
				}
				
				self.formData.append('offer', self.cv);
				self.formData.append('doc', self.file);
				
				axios.post('/api/attachments/upload', self.formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(function (response) {
					self.savedMainInfo = true;
					
					setTimeout(function () {
						self.savedMainInfo = false;
					}, 2500);
					
				})
					.catch(function () {
					
					});
				
			},*/
			onDocReset: function (name) {
				let self = this;
				
				axios.post('/api/attachments/docs/remove', {
					offer: self.cv,
					doc: name,
				}).then(function (response) {
					self.getUploadedDocs();
				})
			},
			onToggleForm: function () {
				this.showForm = !this.showForm;
			},
		}
	}
</script>

<style scoped>

</style>