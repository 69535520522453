const packagesDropdownTitle = document.querySelector(".packages-dropdown-mob .packages-dropdown-title");

if(packagesDropdownTitle){
    packagesDropdownTitle.addEventListener('click', () => {
        packagesDropdownTitle.classList.toggle('packages-dropdown-title__open');
        const rows = document.querySelectorAll('.packages-dropdown-mob .packages-dropdown-mob__row');
        rows.forEach(row=>{
            row.classList.toggle('d-none');
        })
    });
}
