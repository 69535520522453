<template>
	
	<div>
		<p class="new-job-add-block-wrap-title" id="blockMain">
			{{ mainTitle }}
		</p>
		
		<form @submit="onSubmitForm" novalidate="true" method="post">
			<div class="form-group validate autocomplete vacancy-title-block"
			     id="cv-main-block">
				<div class="new-job-add-block-wrap-row" v-bind:class="[titleHasError ? 'is-invalid-block' : '']">
					<div class="new-job-add-block-wrap-row-left">
						<label class="form-field-title" for="cv_main_title">
							{{ fieldLabels.title }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="new-job-add-block-wrap-row-right">
						<input type="text" id="cv_main_title" name="cv_main[title]" maxlength="1024"
						       minlength="" :placeholder="fieldLabels.title" class="professions-typeahead form-control"
						       v-model="title" v-bind:class="[titleHasError ? 'is-invalid' : '']" @change="onTitleChange">
						<div class="invalid-feedback"></div>
					</div>
				</div>
			</div>
			<div class="form-group locations-select-container google-select">
				<div class="new-job-add-block-wrap-row form-group"
				     v-bind:class="[locationHasError ? 'is-invalid-block' : '']">
					<div class="new-job-add-block-wrap-row-left">
						<label class="form-field-title" for="locations">
							{{ fieldLabels.location }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="new-job-add-block-wrap-row-right">
						<div class="wrap-form-control">
						<select id="locations" name="cv_main[location]" required="required"
						        class="form-control" v-model="location"
						        v-bind:class="[locationHasError ? 'is-invalid' : '']">
							<option value="" selected="selected">{{ fieldPlaceholders.location }}</option>
							
							<option v-for="(code, location) in locations" :value="code">{{ location }}</option>
						</select>
						</div>
						<div class="invalid-feedback"></div>
					</div>
				</div>
			</div>
			
			<div class="form-group reset-select block-lang mb-3 ">
				<div class="new-job-add-block-wrap-row"
				     v-bind:class="[postingLanguageHasError ? 'is-invalid-block' : '']">
					<div class="new-job-add-block-wrap-row-left">
						<label class="form-field-title" for="cv_main_language">
							{{ fieldLabels.postingIn }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="new-job-add-block-wrap-row-right">
						<div class="wrap-form-control">
							
							<select id="cv_main_language" name="cv_main[language]" required="required"
							        class="form-control chosen-select-small chosen-select" v-model="postingLanguage"
							        v-bind:class="[postingLanguageHasError ? 'is-invalid' : '']">
								<option value="" selected="selected">
									{{ fieldPlaceholders.postingIn }}
								</option>
								<option v-for="(language, uuid) in languages" :value="uuid">
									{{ language }}
								</option>
							</select>
							
						</div>
						<div class="invalid-feedback"></div>
					</div>
				</div>
			</div>
			
			<div class="form-group">
				<div class="new-job-add-block-wrap-row" v-bind:class="[sectorHasError ? 'is-invalid-block' : '']">
					<div class="new-job-add-block-wrap-row-left">
						<label class="form-field-title" for="cv_main_sector">
							{{ fieldLabels.sector }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="new-job-add-block-wrap-row-right">
						<div class="wrap-form-control">
							<select id="cv_main_sector" name="cv_main[sector]" class="form-control" v-model="sector"
							        v-bind:class="[sectorHasError ? 'is-invalid' : '']">
								<option value="">{{ fieldPlaceholders.sector }}</option>
								
								<option v-for="(sector, code) in sectors" :value="code">
									{{ sector }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			
			<div class="form-group ">
				<div class="new-job-add-block-wrap-row" v-bind:class="[employmentHasError ? 'is-invalid-block' : '']">
					<div class="new-job-add-block-wrap-row-left">
						<label class="form-field-title" for="cv_main_employmentType">
							{{ fieldLabels.employment }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="new-job-add-block-wrap-row-right">
						<div class="wrap-form-control">
							<select id="cv_main_employmentType" name="cv_main[employmentType]" class="form-control"
							        v-model="employment" v-bind:class="[employmentHasError ? 'is-invalid' : '']">
								<option value="">{{ fieldPlaceholders.employment }}</option>
								
								<option v-for="(employment, uuid) in employmentTypes" :value="uuid">
									{{ employment }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			
			<div class="form-group ">
				<div class="new-job-add-block-wrap-row" v-bind:class="[salaryHasError ? 'is-invalid-block' : '']">
					<div class="new-job-add-block-wrap-row-left">
						<label class="form-field-title" for="cv_main_salary">
							{{ fieldLabels.salary }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="new-job-add-block-wrap-row-right">
						<div class="wrap-form-control">
							<select id="cv_main_salary" name="cv_main[salary]" class="form-control" v-model="salary"
							        v-bind:class="[salaryHasError ? 'is-invalid' : '']">
								
								<option disabled value="">
									{{ fieldLabels.salary }}
								</option>
								
								<option v-for="(salary, uuid) in salaries" :value="uuid">{{ salary }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			
			<div class="form-group validate autocomplete vacancy-title-block">
				<div class="new-job-add-block-wrap-row" v-bind:class="[contentHasError ? 'is-invalid-block' : '']">
					<div class="new-job-add-block-wrap-row-left">
						<label class="form-field-title" for="cv_main_content">
							{{ fieldLabels.content }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="new-job-add-block-wrap-row-right">
						<textarea type="text" id="cv_main_content" name="cv_main[content]" maxlength="1024"
						          minlength="50" :placeholder="fieldLabels.content" class="professions-typeahead form-control"
						          v-model="content" v-bind:class="[contentHasError ? 'is-invalid' : '']"/>
						
						<div class="invalid-feedback"></div>
					</div>
				</div>
			</div>
			
			<div class="form-group">
				<div class="new-job-add-block-wrap-row">
					<label class="form-field-title" for="cv_relocate">
						{{ fieldLabels.relocate }}
					</label>
					<div class="new-job-add-block-wrap-row-right custom-checkbox-wrapper">
						<input type="checkbox" v-model="relocate" id="cv_relocate" class="custom-checkbox-wrapper__input">
						<b class="custom-checkbox-wrapper__b"></b>
					</div>
				</div>
			</div>
			
			<div class="form-group">
				<div class="new-job-add-block-wrap-row">
					<label class="form-field-title" for="cv_is_public">
						{{ fieldLabels.isPublic }}
					</label>
					<div class="new-job-add-block-wrap-row-right custom-checkbox-wrapper">
						<input type="checkbox" v-model="isPublic" id="cv_is_public" class="custom-checkbox-wrapper__input">
						<b class="custom-checkbox-wrapper__b"></b>
					</div>
				</div>
			</div>
			
			<div class="form-group btn-save-mob for-line-mob">
				<div class="new-job-add-block-wrap-row">
					<div class="new-job-add-block-wrap-row-left"></div>
					<div class="new-job-add-block-wrap-row-right text-right">
						<button class="btn" v-bind:class="[savedMainInfo ? 'btn-success' : 'btn-outline-primary']">
							<span v-if="savedMainInfo"><i class="fas fa-check"/> </span>
							<span v-else><i class="fas fa-save"/> {{ saveTitle }}</span>
						</button>
					</div>
				</div>
			</div>

			<div class="alert alert-success" role="alert" v-if="savedMainInfo">
				Your changes has been saved!
			</div>

			<div class="alert alert-danger" role="alert" v-if="false">
				Your changes has not been saved!
			</div>
		</form>
	</div>
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "CvMainForm",
		data() {
			return {
				'blockHtml': '',
				title: null,
				content: null,
				location: null,
				postingLanguage: null,
				employment: null,
				salary: null,
				sector: null,
				relocate: true,
				isPublic: true,
				fieldLabels: this.labels,
				fieldPlaceholders: this.placeholders,
				'titleHasError': false,
				'locationHasError': false,
				'postingLanguageHasError': false,
				'employmentHasError': false,
				'salaryHasError': false,
				'sectorHasError': false,
				'contentHasError': false,
				'savedMainInfo': false,
				'showSuccess': false,
				'offerId': null,
				languages: null,
				locations: null,
				sectors: [],
				employmentTypes: [],
				salaries: [],
				errors: [],
				code: this.codeProp,
			}
		},
		props: {
			fields: Object,
			labels: Object,
			placeholders: Object,
			languagesString: String,
			mainTitle: String,
			saveTitle: String,
			codeProp: String,
		},
		mounted() {
			let self = this;
			
			this.languages = this.languagesString ? JSON.parse(this.languagesString) : [];

			Promise.all([self.getLocations(), self.getSectors(), self.getEmploymentTypes(), self.getSalaries(), self.getMainInfo()])
				.then(function (results) {});
		},
		methods: {
			validateForm(event) {
				let self = this;
				
				if (!self.title) {
					this.errors.push('title');
					self.titleHasError = true;
				} else {
					this.clearErrorsByField('title');
					self.titleHasError = false;
				}
				
				if (!self.location) {
					this.errors.push('location');
					self.locationHasError = true;
				} else {
					this.clearErrorsByField('location');
					self.locationHasError = false;
				}
				
				if (!self.postingLanguage) {
					this.errors.push('posting-in');
					self.postingLanguageHasError = true;
				} else {
					this.clearErrorsByField('posting-in');
					self.postingLanguageHasError = false;
				}
				
				if (!self.sector) {
					this.errors.push('sector');
					self.sectorHasError = true;
				} else {
					this.clearErrorsByField('sector');
					self.sectorHasError = false;
				}
				
				if (!self.salary) {
					this.errors.push('salary');
					self.salaryHasError = true;
				} else {
					this.clearErrorsByField('salary');
					self.salaryHasError = false;
				}
				
				if (!self.employment) {
					this.errors.push('employment');
					self.employmentHasError = true;
				} else {
					this.clearErrorsByField('employment');
					self.employmentHasError = false;
				}
				
				if (!self.content) {
					this.errors.push('description');
					self.contentHasError = true;
				} else {
					this.clearErrorsByField('description');
					self.contentHasError = false;
				}
			},
			onSubmitForm(event) {
				
				let self = this;
				
				this.validateForm(event);
				
				if (!self.errors.length) {
					let fieldsValues = {
						title: self.title,
						location: self.location,
						language: self.postingLanguage,
						employment: self.employment,
						salary: self.salary,
						sector: self.sector,
						content: self.content,
						code: self.code,
						relocate: self.relocate,
						isPublic: self.isPublic
					};
					
					let apiUrl = '/api/cv/save';
					
					axios.post(apiUrl, {
						'cv_main': fieldsValues
					}).then(function (response) {
						
						self.code = response.data.code;
						self.savedMainInfo = true;
						self.showSuccess = true;
						
						setTimeout(function () {
							self.savedMainInfo = false;
						}, 2500);
						
						window.history.pushState({ 'page': 'update-cv' }, self.title, '/cv/update/'+response.data.code);

						return self.$emit('submit', response.data.code);
						
					}).catch(function (response) {
						console.log(response);
					});
					
					this.clearErrors();
				}
				
				event.preventDefault();
			},
			validEmail: function (email) {
				var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			},
			clearErrorsByField: function(field) {
				let errorIndex = this.errors.indexOf(field);
				
				if(errorIndex > -1) {
					this.errors.splice(errorIndex, 1);
				}
			},
			getMainInfo: function() {
				
				let self = this;

				axios.post('/api/cv/data', {
					'code': self.code
				}).then(function (response) {
					self.title = response.data.title ?? null;
					self.location = response.data.countryCode ?? '';
					self.postingLanguage = response.data.postingIn ?? '';
					self.sector = response.data.sector ?? '';
					self.salary = response.data.salary ?? '';
					self.employment = response.data.employment ?? '';
					self.content = response.data.description ?? '';
					self.relocate = response.data.isRelocate;
					self.isPublic = response.data.isPublic;
				});
			},
			getLanguages: function () {
				let self = this;
				
				axios.get('/api/languages')
					.then(function (response) {
						self.languages = response.data;
					});
			},
			getLocations: function () {
				let self = this;
				
				axios.get('/api/locations')
					.then(function(response) {
						self.locations = response.data
					});
			},
			getSectors: function () {
				let self = this;
				
				axios.get('/api/sectors')
					.then(function(response) {
						self.sectors = response.data;
					});
			},
			getEmploymentTypes: function() {
				let self = this;
				
				axios.get('/api/employment-types')
					.then(function(response) {
						self.employmentTypes = response.data;
					});
			},
			getSalaries: function() {
				let self = this;
				
				axios.get('/api/salaries')
					.then(function(response) {
						self.salaries = response.data;
					});
			},
			onTitleChange: function (event) {
				/*  @todo implement later */
				// return this.$emit('change', this.title);
			},
			clearErrors: function() {
				let self = this;
				self.titleHasError = false;
				self.locationHasError = false;
				self.sectorHasError = false;
				self.employmentHasError = false;
				self.postingLanguageHasError = false;
				self.salaryHasError = false;
				self.contentHasError = false;
			},
		}
	}
</script>

<style scoped>

</style>