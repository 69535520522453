import {autocomplete} from "./Components/ProfessionsAutocomplete";

const btnNotification = document.querySelector('.notification-block-header .fa-bell');
const notificationBlock = document.querySelector('.notification-block-list');
let flagOpenNotification = false;

if (btnNotification) {
	btnNotification.classList.contains('notification-block-open') ? flagOpenNotification = true : flagOpenNotification = false;

	const toggleMenu = () => {
		flagOpenNotification = !flagOpenNotification;
		notificationBlock.classList.toggle('d-none');
		btnNotification.classList.toggle('notification-block-open');
	}

	btnNotification.addEventListener('click', e => {
		e.stopPropagation();
		toggleMenu();
	});

	document.addEventListener('click', e => {
		let target = e.target;
		let its_menu = target === notificationBlock || notificationBlock.contains(target);
		let its_hamburger = target === btnNotification;
		let menu_is_active = btnNotification.classList.contains('notification-block-open');

		if (!its_menu && !its_hamburger && menu_is_active) {
			toggleMenu();
		}
	})


	// markAsRead();
	
	// markAllRead();

}

/*function markAsRead() {
	
	let markAsReadEl = document.querySelectorAll(".notification-block-list .mark-as-read");
	
	if (markAsReadEl) {
		markAsReadEl.forEach(el => {
			el.addEventListener('click', (event) => {
				event.stopPropagation();
				
				fetch('/api/messages/user/'+ event.target.getAttribute('data-target-id') +'/mark-as-read')
					.then(function(response) {
						el.parentElement.remove();
						markAsReadEl = document.querySelectorAll(".notification-block-list .fa-times");
						markAsReadEl.length === 0 ? notificationBlock.classList.add('no-notification') : notificationBlock.classList.remove('no-notification');
					})
				
			});
		});
	}
}

function markAllRead() {
	const readAllMessagesEl = document.querySelector('.btn-close-all a');
	
	if (readAllMessagesEl) {
		let notificationItemEls = document.querySelectorAll('li.notification-item');
		
		readAllMessagesEl.addEventListener('click', event => {
			
			fetch('/api/messages/user/mark-all-read')
				.then(function(response) {
					
					if(notificationItemEls && notificationItemEls.length > 0) {
						notificationItemEls.forEach(notificationItem => {
							notificationItem.remove();
						});
						
						notificationBlock.classList.add('no-notification');
					}
					
				})
			
		});
	}
}*/
