const btnsCardRate = document.querySelectorAll(".card-label");

if (btnsCardRate) {
	btnsCardRate.forEach(btn => {
		btn.addEventListener('click', () => {
			btnsCardRate.forEach(btnCard => {
				btnCard.parentNode.parentNode.classList.remove('active-tariff')
			});
			btn.parentNode.parentNode.classList.add('active-tariff')
		});
	});
}