const companyForm = document.querySelector(".company-manage-form");

if (companyForm) {
    const btnSubmitCompanyForm = companyForm.querySelector(".btn-primary");
    const btnRemoveCompanyForm = companyForm.querySelector(".btn-outline-danger");

    const blockCompanyTitle = companyForm.querySelector(".company-title-block");
    let fieldCompanyTitle = blockCompanyTitle.querySelector(".form-control");

    const blockCompanyCid = companyForm.querySelector(".company-cid-block");
    let fieldCompanyCid = blockCompanyCid.querySelector(".form-control");

    const blockCompanyType = companyForm.querySelector(".company-type-block");
    let fieldCompanyType = blockCompanyType.querySelector(".form-control");

    const blockCompanySize = companyForm.querySelector(".company-size-block");
    let fieldCompanySize = blockCompanySize.querySelector(".form-control");

    // const blockCompanyActivities = companyForm.querySelector(".company-activities-block");

    btnSubmitCompanyForm.addEventListener('click', (e) => {
        e.preventDefault();

        const fieldCompanyTitleValue = fieldCompanyTitle.value;
        validateField(blockCompanyTitle, fieldCompanyTitleValue);

        const fieldCompanyCidValue = fieldCompanyCid.value;
        validateField(blockCompanyCid, fieldCompanyCidValue);

        const fieldCompanyTypeValue = fieldCompanyType.options[fieldCompanyType.selectedIndex].value;
        validateField(blockCompanyType, fieldCompanyTypeValue);

        const fieldCompanySizeValue = fieldCompanySize.options[fieldCompanySize.selectedIndex].value;
        validateField(blockCompanySize, fieldCompanySizeValue);

        // var fieldCompanyActivitiesValue =document.getElementsByClassName("choices__list choices__list--multiple")[0].innerHTML;
        // validateFieldActivities(blockCompanyActivities, fieldCompanyActivitiesValue);


        if (fieldCompanyTitleValue && fieldCompanyCidValue && fieldCompanyTypeValue && fieldCompanySizeValue) {
            companyForm.submit();
            // btnRemoveCompanyForm.classList.remove('d-none');
        }
    });

    fieldCompanyTitle.addEventListener('input', (e) => {
        const fieldCompanyTitleValue = fieldCompanyTitle.value;
        validateField(blockCompanyTitle, fieldCompanyTitleValue);
    });

    fieldCompanyCid.addEventListener('input', (e) => {
        const fieldCompanyCidValue = fieldCompanyCid.value;
        validateField(blockCompanyCid, fieldCompanyCidValue);
    });

    fieldCompanyType.addEventListener('change', (e) => {
        const fieldCompanyTypeValue = fieldCompanyType.options[fieldCompanyType.selectedIndex].value;
        validateField(blockCompanyType, fieldCompanyTypeValue);
    });

    fieldCompanySize.addEventListener('change', (e) => {
        const fieldCompanySizeValue = fieldCompanySize.options[fieldCompanySize.selectedIndex].value;
        validateField(blockCompanySize, fieldCompanySizeValue);
    });
}

function validateField(block, val) {
    const blockErrorText = block.querySelector(".invalid-feedback");
    const errorText = blockErrorText.getAttribute('data-error-text');
    if (!val) {
        block.classList.add('is-invalid-block');
        block.querySelector(".form-control").classList.add('is-invalid');
    } else if (errorText && !val) {
        block.classList.add('is-invalid-block');
        block.querySelector(".form-control").classList.add('is-invalid');
        blockErrorText.querySelector(".error-text__other").innerHTML = errorText;
    } else if (errorText && val) {
        block.classList.add('is-invalid-block');
        block.querySelector(".form-control").classList.add('is-invalid');
        blockErrorText.querySelector(".error-text__other").innerHTML = errorText;
        blockErrorText.querySelector(".error-text__empty").classList.add('d-none');
    } else {
        block.classList.remove('is-invalid-block');
        block.querySelector(".form-control").classList.remove('is-invalid');
    }
}

function validateFieldActivities(block, val) {
    if (!val) {
        block.classList.add('is-invalid-block');
        block.querySelector(".choices").classList.add('is-invalid');
    } else {
        block.classList.remove('is-invalid-block');
        block.querySelector(".choices").classList.remove('is-invalid');
    }
}

