<template>
	<div v-if="code">
		<button class="btn btn-sm" v-bind:class="[favored ? 'btn-info' : 'btn-outline-info']" v-on:click="favor">
			<i v-bind:class="[favored ? 'fas' : 'far']" class="fa-heart"> </i>
			<span>{{ buttonLabel }}</span>
		</button>
	</div>
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "Favorites",
		data() {
			return {
				favored: false,
				url: ''
			}
		},
		props: [
			'code',
			'type',
			'buttonLabel'
		],
		mounted() {
			let self = this;
			
			axios.get('/api/favorites/'+ this.type + '/'+this.code+'/is-favored')
				.then(function(response) {
					self.favored = response.data.status;
				})
		},
		
		methods: {
			favor() {
				let self = this;
				
				if(!this.favored) {
					this.url = '/api/favorites/'+ this.type +'/'+self.code+'/favor'
				} else {
					this.url = '/api/favorites/'+ this.type +'/'+self.code+'/unfavor'
				}
				
				axios.get(this.url)
					.then(function(response) {
						if(response.status === 200) {
							self.favored = !self.favored;
							// window.location.reload;
							window.location = window.location;
						} else {
							console.info('[E] ' + response.data)
						}
					})
					.catch(function(error) {
						console.error(error);
					})
			}
		}
	}
</script>

<style scoped>

</style>