<template>
	<div>
		<div class="new-job-add-page">
			<div class="container">
				<h2 class="offer-title">
					{{ getPageTitle }}
				</h2>
				<div class="new-job-add-page-content">
					<div class="new-job-add-page-content-left">
						<div>
							<div class="block-content-filter new-cv-add-page">
								<div class="left-part w-100">
									<p class="add-offer-desc">
										{{ pageSubHeader }}
									</p>
									
									<div class="new-job-add-block-wrap" data-position="block1">
										<cv-main-form
												:labels="{
														title: titleLabel,
														location: locationLabel,
														postingIn: postingInLabel,
														sector: sectorLabel,
														employment: employmentLabel,
														salary: salaryLabel,
														content: contentLabel,
														relocate: relocateLabel,
														isPublic: isPublicLabel,
										              }"
												:placeholders="{
													location: locationPlaceholder,
													postingIn: postingInPlaceholder,
													sector: sectorPlaceholder,
													employment: employmentPlaceholder,
												}"

												v-on:submit="onSave"
												v-bind:code-prop="getShortCode"
												v-bind:main-title="mainTitle"
												v-bind:save-title="saveTitle"
												v-bind:languages-string="languagesString"
										/>
									</div>
									<p v-bind:class="{ 'additional-information-title__active' : code }"
									   class="additional-information-title">
										{{ completeYourCvText }}
									</p>
									<div v-bind:class="{ 'new-job-add-block-wrap__active' : code }" class="new-job-add-block-wrap new-job-add-block-wrap__mob"
									     data-position="block2">
										<experiences
												:labels="{
													company: expCompanyNameTitle,
													position: expPositionTitle,
													description: expDescriptionTitle,
													startDate: expStartDateTitle,
													endDate: expEndDateTitle,
													startMonth: expStartDateMonthTitle,
													endMonth: expEndDateMonthTitle,
													stillWorking: expStillWorkingTitle,
													save: saveTitle,
													cancel: cancelTitle,
													addTitle: expAddTitle,
													title: expTitle
												}"
												v-bind:code="code"
												v-bind:months="months"
										/>
									</div>
									<div v-bind:class="{ 'new-job-add-block-wrap__active' : code }"
									     class="new-job-add-block-wrap new-job-add-block-wrap__mob "
									     data-position="block3">
										<education
												v-bind:cv="code"
												:labels="{
													addLanguage: eduAddLanguageLabel,
													educationLevel: eduLevelLabel,
													languageLevel: eduLanguageLevelLabel,
													save: saveTitle,
													cancel: cancelTitle,
													title: eduTitle,
													addTitle: eduAddTitle
												}"
												:placeholders="{
													educationLevel: eduLevelPlaceholder,
													languageLevel: eduLanguageLevelPlaceholder
												}"
												v-bind:languages-string="languagesString"
												v-bind:language-levels-string="languageLevelsString"
												v-bind:education-levels-string="educationLevelsString"
										/>
									</div>
									<div v-bind:class="{ 'new-job-add-block-wrap__active' : code }"
									     class="new-job-add-block-wrap new-job-add-block-wrap__mob">
										<attachments
												v-bind:cv="code"
												v-bind:save-title="saveTitle"
												v-bind:attachments-title="attachmentsTitle"
												v-bind:uploads-doc-label="uploadsDocLabel"
												v-bind:uploads-gallery-label="uploadsGalleryLabel"
												v-bind:uploads-doc-placeholder="uploadsDocPlaceholder"
												v-bind:uploads-add-title="uploadsAddTitle"
										/>
									</div>
									<div class="text-right">
										<div class="alert alert-success" role="alert" v-if="showStatus">
											{{ responseStatus }}
										</div>
										
										<button v-if="code" @click="onDraft" type="button" class="btn btn-outline-dark">
											Draft
										</button>
										
										<button v-if="code" @click="onPublish" type="button"
										        class="btn btn-outline-success">
											Publish
										</button>
										
										<a v-if="code" :href="'/cv/' + getShortCode" class="btn btn-info">
											Preview
										</a>
									</div>
								</div>
								
								<!--								<form-sidebar-->
								<!--										v-bind:main-block-title="sidebarMainTitle"-->
								<!--										v-bind:experience-block-title="sidebarExperienceTitle"-->
								<!--										v-bind:education-block-title="sidebarEducationTitle"-->
								<!--								/>-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	</div>
</template>

<script>
	import * as axios from 'axios';
	
	import CvMainForm from "./CvMainForm";
	import Experiences from "./cv/Experiences";
	import Education from "./cv/Education";
	import Attachments from "./Attachments";
	import AdditionalInfo from "./cv/AdditionalInfo";
	import FormSidebar from "./FormSidebar";
	
	export default {
		name: "CvForm",
		components: {
			FormSidebar,
			AdditionalInfo,
			Experiences,
			CvMainForm,
			Education,
			Attachments
		},
		data() {
			return {
				// cv: this.cv
				showStatus: false,
				responseStatus: '',
				code: this.shortCode,
				title: '',
				description: '',
				location: '',
				postingIn: '',
				sector: null,
				employment: null,
				salary: null,
				experiences: null,
				education: null,
				languageLevels: null,
				isLoaded: false,
			}
		},
		methods: {
			onSave(shortCode) {
				if (!this.code) {
					this.code = shortCode;
				}
			},
			onPublish() {
				let self = this;
				
				axios.post('/api/cv/publish', {
					cv: this.code
				}).then(function (response) {
					self.responseStatus = response.data.status;
					self.showStatus = true;
					
					setTimeout(function () {
						self.showStatus = false;
						self.responseStatus = '';
					}, 2500);
				});
			},
			onDraft() {
				let self = this;
				
				axios.post('/api/cv/draft', {
					cv: this.code
				}).then(function (response) {
					self.showStatus = true;
					self.responseStatus = response.data.status;
					
					setTimeout(function () {
						self.showStatus = false;
						self.responseStatus = '';
					}, 2500);
				})
			},
/*			setData(data) {
				this.title = data.title ?? '';
				this.description = data.description ?? '';
				this.location = data.countryCode ?? null;
				this.postingIn = data.postingIn ?? null;
				this.sector = data.sector ?? null;
				this.employment = data.employment ?? null;
				this.salary = data.salary ?? null;
				this.experiences = data.experiences ?? [];
				this.education = data.education ?? null;
				this.languageLevels = data.languageLevel ?? [];
			}*/
		},
		computed: {
			getShortCode: function () {
				return this.code ? this.code : this.shortCode;
			},
			getPageTitle: function () {
				return this.code ? this.cvUpdateTitle : this.cvCreateTitle;
			}
		},
		mounted() {
/*			let self = this;
			
			if(self.shortCode) {
				axios
					.get('/api/cv/' + self.shortCode + '/data')
					.then(function (response) {
						self.fields = response.data;
					})
			} else {
				self.setData({});
				self.isLoaded = true;
			}*/
		},
		props: {
			titleLabel : String,
			
			mainTitle: String,
			locationLabel : String,
			postingInLabel : String,
			postingInPlaceholder : String,
			sectorLabel : String,
			sectorPlaceholder : String,
			employmentLabel : String,
			employmentPlaceholder : String,
			salaryLabel : String,
			locationPlaceholder : String,
			contentLabel : String,
			relocateLabel : String,
			isPublicLabel: String,
			
			pageSubHeader : String,
			saveTitle : String,
			cancelTitle : String,
			
			expTitle : String,
			expCompanyNameTitle : String,
			expPositionTitle : String,
			expDescriptionTitle : String,
			expStartDateTitle : String,
			expEndDateTitle : String,
			expStillWorkingTitle : String,
			expAddTitle : String,
			expStartDateMonthTitle : String,
			expEndDateMonthTitle : String,
			
			eduTitle : String,
			eduAddTitle : String,
			eduLevelLabel : String,
			eduLevelPlaceholder : String,
			eduLanguageLevelLabel : String,
			eduLanguageLevelPlaceholder : String,
			eduAddLanguageLabel : String,
			
			attachmentsTitle : String,
			uploadsDocLabel : String,
			uploadsGalleryLabel : String,
			uploadsDocPlaceholder : String,
			uploadsAddTitle : String,
			
			months : String,
			languagesString : String,
			employmentsString : String,
			languageLevelsString : String,
			educationLevelsString : String,
			cvCreateTitle : String,
			cvUpdateTitle : String,
			jobCreateTitle : String,
			jobUpdateTitle : String,
			
			sidebarMainTitle : String,
			sidebarExperienceTitle : String,
			sidebarEducationTitle : String,
			// cv,
			shortCode : String,
			completeYourCvText: String,
		}
	}
</script>

<style scoped>

</style>