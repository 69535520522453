const btnRemovePhoto = document.querySelector('.remove-photo');

if(btnRemovePhoto) {
    const fieldPhoto = document.getElementById('photo');

    btnRemovePhoto.addEventListener('click', function () {
        clearInputFile(fieldPhoto);
    })
}

function clearInputFile(f){
    if(f.value){
        try{
            f.value = '';
        }catch(err){
        }
        if(f.value){
            var form = document.createElement('form'), ref = f.nextSibling;
            form.appendChild(f);
            form.reset();
            ref.parentNode.insertBefore(f,ref);
        }
    }

    document.querySelector('.no-user-avatar').classList.remove('d-none');
    document.querySelector('.is-user-avatar').removeAttribute('src');
    btnRemovePhoto.classList.add('d-none');
    document.getElementById('upload-photo-text').classList.remove('d-none');
}
