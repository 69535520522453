<template>
	<div class="comment-list-wrap">
		<div class="toggle-comments mr-4" v-on:click="list">
			<span class="show-comments" v-if="collapsed">{{ this.showCommentsTitle }}: {{comments.length}}</span>
			<span class="show-comments" v-if="!collapsed">{{ this.hideCommentsTitle }}</span>

<!--			<i class="fas fa-reply toggle-comments-icon" v-if="collapsed"></i>-->
			<i class="fas fa-reply toggle-comments-icon" ></i>
		</div>
		
		<div v-if="!collapsed" class="comment-card-wrap">
			<div class="" v-if="loaded && !failed && !pending" v-for="comment in comments" :key="comment.id">
				<div class="comment-card-item">
					<div class="align-items-center d-flex justify-content-between py-2">
						<div class="align-items-center d-flex ">
							<div v-if="comment.isRelatedEmployer" class="company-main-icon reply">
								<i class="far fa-user"></i>
							</div>
							<div v-else class="company-main-icon reply">
								<i  class="far fa-user rounded-circle"></i>
							</div>
							<div class="comment-autor-name">{{ comment.name }}</div>
						</div>
<!--						<div>-->
<!--							<span>{{ comment.createdAt }}</span>-->
<!--						</div>-->
					</div>
					<div class="review-content">
						{{ comment.text }}
					</div>
				</div>
			</div>
			<div class="" v-if="loaded && !pending && !failed && comments.length <= 0">
				<div class="">
					<div class="align-items-center d-flex justify-content-center no-comments-title">
<!--						<i class="fa-clipboard far mr-2"></i>-->
						<span>{{ noCommentsTitle }}</span>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "CommentsList",
		props: {
			showCommentsTitle: String,
			hideCommentsTitle: String,
			code: String,
			noCommentsTitle: String
		},
		data() {
			return {
				collapsed: true,
				comments: [],
				pending: false,
				loaded: false,
				failed: false
			}
		},
		methods: {
			list() {
				let self = this;
				self.collapsed = !self.collapsed;
				
				if(self.comments.length <= 0) {
					self.pending = true;
					
					axios.post('/api/review/' + this.code + '/comments')
						.then(function (response) {
							self.comments = response.data;
							self.pending = false;
							self.loaded = true;
						})
						.catch(function (error) {
							console.log(error);
							self.pending = false;
							self.loaded = true;
							self.failed = true;
						});
				}
			},
			toggleCommentsList() {
			
			}
		}
	}
</script>

<style scoped>

</style>