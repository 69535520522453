$(document).ready(function () {
    $('.logos-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: '<img src="/images/home-employers/arrov-prev.svg" class="slick-prev">',
        nextArrow: '<img src="/images/home-employers/arrov-prev.svg" class="slick-next">',
        autoplay: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: true,
                }
            }
        ]
    });

    $('.our-services-slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        prevArrow: '<img src="/images/home-employers/arrov-prev.svg" class="slick-prev">',
        nextArrow: '<img src="/images/home-employers/arrov-prev.svg" class="slick-next">'
    });

    $('.why-workmarket-slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        prevArrow: '<img src="/images/home-employers/arrov-prev.svg" class="slick-prev">',
        nextArrow: '<img src="/images/home-employers/arrov-prev.svg" class="slick-next">'
    });
});