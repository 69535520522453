var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "pagination" },
    _vm._l(_vm.paginationData.pageRange, function (page) {
      return _c(
        "li",
        {
          staticClass: "page-item",
          class: page === _vm.paginationData.current ? "active" : "",
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              on: {
                click: function ($event) {
                  return _vm.pageClick(page)
                },
              },
            },
            [_vm._v(_vm._s(page))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }