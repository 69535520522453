const preview = function(input, imageIdentifier) {
	if (input.files && input.files[0]) {
		let reader = new FileReader();
		
		reader.onload = function (e) {
			let image = $('#'+imageIdentifier);
			image.attr('src', e.target.result);
			image.attr('width', '70px');
			image.attr('height', '70px');
		};
		
		reader.readAsDataURL(input.files[0]);
	}
};

export default {preview}