<template>
	<div class="subscriptions-list">
		
		<div class="text-center" v-if="!jobsLoaded && !cvsLoaded">
			<i class="fas fa-fw fa-spinner" v-bind:class="[true > 0 ? 'fa-spin' : '']"></i>
		</div>

		<no-data 
			v-if="jobsCount === 0 && cvsCount === 0"
			:labelForJobs = labelSubscribeForJobs
			:labelForCvs = labelSubscribeForCvs
			:title = titleNoSubscriptions
		></no-data>
		
		<List 
			type="jobs" 
			:locations=locations 
			:languages=languages
			:labelPreview = labelPreview
			:labelUnsubscribe = labelUnsubscribe
			:labelDisable = labelDisable
			:labelEnable = labelEnable
			:labelConfirm = labelConfirm
			:title = titleJobs
			:subscriptionToken = subscriptionToken
		></List>
		<List 
			type="cvs" 
			:locations=locations 
			:languages=languages
			:labelPreview = labelPreview
			:labelUnsubscribe = labelUnsubscribe
			:labelDisable = labelDisable
			:labelEnable = labelEnable
			:labelConfirm = labelConfirm
			:title = titleCvs
			:subscriptionToken = subscriptionToken
		></List>
	</div>
</template>

<script>

import * as axios from 'axios';
import List from "./List"
import NoData from "./SubscriptionsNoData"

export default {

	name: 'Subscriptions',
	components: {
		NoData, 
		List
	},
  
	props: {
		labelPreview: String,
		labelUnsubscribe: String,
		labelDisable: String,
		labelEnable: String,
		labelConfirm: String,
		titleJobs: String,
		titleCvs: String,
		labelSubscribeForJobs: String,
		labelSubscribeForCvs: String,
		titleNoSubscriptions: String,
		subscriptionToken: String
	},

	data () {
		return {
			locations: {},
			languages: {},
			jobsLoaded: false,
			cvsLoaded: false,
			jobsCount: -1,
			cvsCount: -1
		}
	},

	mounted() {
		this.$root.$on('showToast', (type, message) => {
			this.showToast(type, message);
		});

		this.$root.$on('onDataLoaded', (type, count) => {
			this.onDataLoaded(type, typeof count !== 'undefined' ? count : 0);
		})

		this.getLanguages();
		this.getLocations();
	},

	methods: {
		onDataLoaded: function(type, count) {
			if(type === 'jobs') {
				this.jobsLoaded = (type === 'jobs');
				this.jobsCount = count;
			} else if(type === 'cvs') {
				this.cvsLoaded = (type === 'cvs');
				this.cvsCount = count;
			}
		},

		showToast: function(type, message) {
			this.$toast.open({
				type: type,
				message: message
			});
		},

		getLocations: function() {
			let self = this;

			axios.get('/api/locations')
				.then(function(response) {
					self.locations = response.data;
				})
		},

		getLanguages: function() {
			let self = this;

			axios.get('/api/languages/key-names')
				.then(function(response) {
					self.languages = response.data;
				})
		},
	},

}

</script>

<style lang="css" scoped>
</style>