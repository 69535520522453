// add field check-company
const btnCheckCompany = document.getElementsByName('allCompanies');
const fieldCheckCompany = document.querySelector('.check-company');

if (btnCheckCompany) {
	for (let i = 0; i < btnCheckCompany.length; i++) {
		btnCheckCompany[i].addEventListener('click', () => {
			btnCheckCompany[i].value === 'option2' ? fieldCheckCompany.classList.remove('d-none') : fieldCheckCompany.classList.add('d-none');
		})
	}
}

// vacancy form show
const btnVacancyFormShow = document.querySelector('.btn-vacancy-form-show');
const btnVacancyFormHide = document.querySelector('.btn-vacancy-form-hide');
const vacancyForm = document.querySelector('.vacancy-page-form');

if (btnVacancyFormShow) {
	let vacancyFormFlag = false;

	btnVacancyFormShow.addEventListener('click', () => {
		if (vacancyForm) {
			vacancyFormFlag ? vacancyForm.classList.add('d-none') : vacancyForm.classList.remove('d-none');
			vacancyFormFlag = !vacancyFormFlag;
		}
	});

	btnVacancyFormHide.addEventListener('click', () => {
		if (vacancyForm) {
			vacancyForm.classList.add('d-none');
			vacancyFormFlag = false;
		}
	});
}

/*
* @todo refactor this crap
* */

// btn show phone
const btnShowPhone = document.querySelector('.show-phone');

if (btnShowPhone) {
	btnShowPhone.addEventListener('click', () => {
		btnShowPhone.innerHTML = "+420 777 777 777";
	});
}

// show block questions form
const btnShowQuestionsForm = document.querySelector('.open-form');
const questionsForm = document.querySelector('.block-questions-form');
const btnCloseQuestionsForm = document.querySelector('.block-questions-form .btn-blue-outl');

if (btnShowQuestionsForm) {
	btnShowQuestionsForm.addEventListener('click', () => {
		questionsForm.classList.toggle('d-none');
	});
}

if (btnCloseQuestionsForm) {
	btnCloseQuestionsForm.addEventListener('click', () => {
		questionsForm.classList.toggle('d-none');
	});
}

// hide menu
const btnOpenMenu = document.querySelector('.btn-open-menu');
let flagOpenMenu = false;

if (btnOpenMenu) {
	btnOpenMenu.classList.contains('collapsed') ? flagOpenMenu = true : flagOpenMenu = false;

	btnOpenMenu.addEventListener('click', () => {
		flagOpenMenu = !flagOpenMenu;
	});

	document.onclick = function (e) {
		flagOpenMenu && onClick(e, '#desktopMenu', '.btn-open-menu');
	}
}

function onClick(e, blockTag, btnTag) {
	e = e || window.event;
	const eventClick = new Event('click', {bubbles: true});
	const block = document.querySelector(blockTag);
	const btn = document.querySelector(btnTag);
	let target = e.target || e.srcElement;

	while (target.parentNode && target.tagName.toLowerCase() !== 'body') {
		if (target === block || target === btn) {
			return false;
		}
		target = target.parentNode;
	}

	btnOpenMenu.dispatchEvent(eventClick);
}

// toggle payment method
const paymentMethodList = document.querySelectorAll(".payment-method-item");

if (paymentMethodList) {
	paymentMethodList.forEach(item => {
		item.addEventListener('click', () => {
			paymentMethodList.forEach(method => {
				method.parentElement.classList.remove('payment-method-active');
			});
			item.parentElement.classList.toggle('payment-method-active');
		});
	});
}

// toggle dropdown title
const dropdownTitleList = document.querySelectorAll(".block-dropdown-title");

if (dropdownTitleList) {
	dropdownTitleList.forEach(item => {
		item.addEventListener('click', () => {
			item.parentElement.classList.toggle('is-open');
		});
	});
}


// toggle employer
const typeMethodList = document.querySelectorAll(".type-employer-item");

if (typeMethodList) {
	typeMethodList.forEach(item => {
		item.addEventListener('click', () => {
			typeMethodList.forEach(method => {
				method.parentElement.classList.remove('type-employer-active');
			});
			item.parentElement.classList.toggle('type-employer-active');
		});
	});
}

// field add file
const fieldAddFile = document.getElementById("uploadBtn");
const blockFile = document.getElementById("uploadFile");
const btnDeleteFile = document.querySelector(".btn-delete-file");

if (fieldAddFile) {
	
	let acceptedMimeTypes = ['application/pdf',
		'application/x-pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
	
	verifyField();
	fieldAddFile.onchange = function () {
		
		if(this.files.length > 0) {
			
			if((this.files[0].size / 1000 / 1000) > 4) {
				alert('Max 4MB');
				return;
			}
			
			if(!acceptedMimeTypes.includes(this.files[0].type)) {
				alert('DOC, DOCX, PDF ');
				return;
			}
		}

		
		blockFile.value = this.value.replace("C:\\fakepath\\", "");
		verifyField();
	};
	btnDeleteFile.addEventListener('click', function () {
		blockFile.value = '';
		verifyField();
	});
}

function verifyField() {
	blockFile.value === '' ? btnDeleteFile.classList.add('d-none') : btnDeleteFile.classList.remove('d-none');
}


// show form response vacancy
const btnShowResponseVacancy = document.querySelector(".btn-show-filter-mob");
const btnHideResponseVacancy = document.querySelector(".btn-close");
const formResponseVacancy = document.querySelector(".form-response-vacancy");

if (btnShowResponseVacancy) {
	btnShowResponseVacancy.addEventListener('click', () => {
		formResponseVacancy.classList.add('form-response-vacancy-show');
		btnShowResponseVacancy.classList.remove('show');
	});
}

if (btnHideResponseVacancy) {
	btnHideResponseVacancy.addEventListener('click', () => {
		formResponseVacancy.classList.remove('form-response-vacancy-show');
		btnShowResponseVacancy.classList.add('show');
	});
}