import { render, staticRenderFns } from "./Education.vue?vue&type=template&id=39b4383c&scoped=true&"
import script from "./Education.vue?vue&type=script&lang=js&"
export * from "./Education.vue?vue&type=script&lang=js&"
import style0 from "./Education.vue?vue&type=style&index=0&id=39b4383c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b4383c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\ruslan\\workmarket.eu\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39b4383c')) {
      api.createRecord('39b4383c', component.options)
    } else {
      api.reload('39b4383c', component.options)
    }
    module.hot.accept("./Education.vue?vue&type=template&id=39b4383c&scoped=true&", function () {
      api.rerender('39b4383c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/Vue/components/offers/cv/Education.vue"
export default component.exports