import ImagePreview from "../../ImagePreview";

let avatarDivEl = document.getElementById('photo-uploader'),
	reuploadLinkEl = document.getElementById('add-user-photo'),
	imageInputEl = document.getElementById('photo'),
	uploadText = document.getElementById('upload-photo-text')
	;

if(uploadText) {
	uploadText.addEventListener('click', function (e) {
		imageInputEl.click();
	})
}

if(avatarDivEl) {
	avatarDivEl.addEventListener('click', function (e) {
		imageInputEl.click();
	})
}

if(reuploadLinkEl) {
	reuploadLinkEl.addEventListener('click', function (e) {
		imageInputEl.click();
	})
}

if(imageInputEl) {
	imageInputEl.addEventListener('change', function (e) {
		
		console.info('changed...');
		
		ImagePreview.preview(e.target, 'user-avatar-temp');
		
		let removeText = document.getElementById('remove-photo-text');
		
		document.getElementById('user-avatar').classList.add('d-none');
		document.getElementById('user-avatar-temp').classList.remove('d-none');
		// uploadText.classList.remove('form-avatar-text');
		// uploadText.classList.add('d-none');
		
		// removeText.classList.remove('d-none');
		// removeText.classList.remove('form-avatar-text');
		
		// avatarDivEl.parentElement.classList.add('_delete');

		const btnRemovePhoto = document.querySelector('.remove-photo');
		if(btnRemovePhoto){
			btnRemovePhoto.classList.remove('d-none');
			uploadText.classList.add('d-none');
		}
	})
}


if(document.getElementById('remove-user-photo')) {
	document.getElementById('remove-user-photo').addEventListener('click', function () {
		if (imageInputEl) {
			let imageInputEl = document.getElementById('photo'),
				changeEvent = new Event('change');
			
			// imageInputEl.value = '';
			// imageInputEl.dispatchEvent(changeEvent);
			
			document.getElementById('user-avatar').src = '';
			document.getElementById('user-avatar-temp').src = ''
		}
		
		
		if (document.getElementById('img-src')) {
			document.getElementById('img-src').value = '';
		}
		
		avatarDivEl.parentElement.classList.remove('_delete');
		
	});
}