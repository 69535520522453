var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subscriptions-list" },
    [
      !_vm.jobsLoaded && !_vm.cvsLoaded
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", {
              staticClass: "fas fa-fw fa-spinner",
              class: [true > 0 ? "fa-spin" : ""],
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.jobsCount === 0 && _vm.cvsCount === 0
        ? _c("no-data", {
            attrs: {
              labelForJobs: _vm.labelSubscribeForJobs,
              labelForCvs: _vm.labelSubscribeForCvs,
              title: _vm.titleNoSubscriptions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("List", {
        attrs: {
          type: "jobs",
          locations: _vm.locations,
          languages: _vm.languages,
          labelPreview: _vm.labelPreview,
          labelUnsubscribe: _vm.labelUnsubscribe,
          labelDisable: _vm.labelDisable,
          labelEnable: _vm.labelEnable,
          labelConfirm: _vm.labelConfirm,
          title: _vm.titleJobs,
          subscriptionToken: _vm.subscriptionToken,
        },
      }),
      _vm._v(" "),
      _c("List", {
        attrs: {
          type: "cvs",
          locations: _vm.locations,
          languages: _vm.languages,
          labelPreview: _vm.labelPreview,
          labelUnsubscribe: _vm.labelUnsubscribe,
          labelDisable: _vm.labelDisable,
          labelEnable: _vm.labelEnable,
          labelConfirm: _vm.labelConfirm,
          title: _vm.titleCvs,
          subscriptionToken: _vm.subscriptionToken,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }