const btnsReadMore = document.querySelectorAll(".promo-page .read-more");

if (btnsReadMore) {
	btnsReadMore.forEach(btn => {
		btn.addEventListener('click', () => {
			if (btn.parentElement.classList.contains('open-read-more')) {
				btn.innerHTML = 'reed more';
			} else {
				btn.innerHTML = 'hide text';
			}
			btn.parentElement.classList.toggle('open-read-more');
		});
	});
}