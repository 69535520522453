let updatePasswordCheckboxEl = document.getElementById('updatePassword'),
	passwordInputEl = document.querySelectorAll('input[type="password"]');

if(updatePasswordCheckboxEl) {
	updatePasswordCheckboxEl.addEventListener('change', function (e) {
		Array.from(passwordInputEl).forEach(function(passwordEl, index, array) {
			if(!e.target.checked) {
				passwordEl.value = '';
				passwordEl.defaultValue = '';
			}
			passwordEl.disabled = !e.target.checked;
		})
	})
}