var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "position-fixed text-center w-100",
      staticStyle: { bottom: "0", "z-index": "1000" },
    },
    _vm._l(_vm.messages, function (message) {
      return _c(
        "div",
        {
          staticClass: "py-3 broadcast-message-container",
          style: {
            backgroundColor: "#" + message.bgColor,
            color: "#" + message.textColor,
          },
        },
        [
          _c(
            "p",
            {
              staticClass:
                "broadcast-message-text broadcast-message-text-expand",
              on: { click: _vm.messageToggle },
            },
            [
              _c("i", {
                staticClass: "fas pr-1 text-warning",
                class: [_vm.icon[message.type]],
              }),
              _vm._v("\n\t\t\t" + _vm._s(message.message) + "\n\t\t"),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }