var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      {
        staticClass: "new-job-add-block-wrap-title",
        attrs: { id: "blockMain" },
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.mainTitle) + "\n\t")]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { novalidate: "true", method: "post" },
        on: { submit: _vm.onSubmitForm },
      },
      [
        _c(
          "div",
          {
            staticClass: "form-group validate autocomplete vacancy-title-block",
            attrs: { id: "cv-main-block" },
          },
          [
            _c(
              "div",
              {
                staticClass: "new-job-add-block-wrap-row",
                class: [_vm.titleHasError ? "is-invalid-block" : ""],
              },
              [
                _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-field-title",
                      attrs: { for: "cv_main_title" },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.fieldLabels.title) +
                          "\n\t\t\t\t\t\t"
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.title,
                        expression: "title",
                      },
                    ],
                    staticClass: "professions-typeahead form-control",
                    class: [_vm.titleHasError ? "is-invalid" : ""],
                    attrs: {
                      type: "text",
                      id: "cv_main_title",
                      name: "cv_main[title]",
                      maxlength: "1024",
                      minlength: "",
                      placeholder: _vm.fieldLabels.title,
                    },
                    domProps: { value: _vm.title },
                    on: {
                      change: _vm.onTitleChange,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.title = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "invalid-feedback" }),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group locations-select-container google-select",
          },
          [
            _c(
              "div",
              {
                staticClass: "new-job-add-block-wrap-row form-group",
                class: [_vm.locationHasError ? "is-invalid-block" : ""],
              },
              [
                _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-field-title",
                      attrs: { for: "locations" },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.fieldLabels.location) +
                          "\n\t\t\t\t\t\t"
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
                  _c("div", { staticClass: "wrap-form-control" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.location,
                            expression: "location",
                          },
                        ],
                        staticClass: "form-control",
                        class: [_vm.locationHasError ? "is-invalid" : ""],
                        attrs: {
                          id: "locations",
                          name: "cv_main[location]",
                          required: "required",
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.location = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "", selected: "selected" } },
                          [_vm._v(_vm._s(_vm.fieldPlaceholders.location))]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.locations, function (code, location) {
                          return _c("option", { domProps: { value: code } }, [
                            _vm._v(_vm._s(location)),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "invalid-feedback" }),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group reset-select block-lang mb-3 " }, [
          _c(
            "div",
            {
              staticClass: "new-job-add-block-wrap-row",
              class: [_vm.postingLanguageHasError ? "is-invalid-block" : ""],
            },
            [
              _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-field-title",
                    attrs: { for: "cv_main_language" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.fieldLabels.postingIn) +
                        "\n\t\t\t\t\t\t"
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
                _c("div", { staticClass: "wrap-form-control" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.postingLanguage,
                          expression: "postingLanguage",
                        },
                      ],
                      staticClass:
                        "form-control chosen-select-small chosen-select",
                      class: [_vm.postingLanguageHasError ? "is-invalid" : ""],
                      attrs: {
                        id: "cv_main_language",
                        name: "cv_main[language]",
                        required: "required",
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.postingLanguage = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        { attrs: { value: "", selected: "selected" } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.fieldPlaceholders.postingIn) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.languages, function (language, uuid) {
                        return _c("option", { domProps: { value: uuid } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(language) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "invalid-feedback" }),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            {
              staticClass: "new-job-add-block-wrap-row",
              class: [_vm.sectorHasError ? "is-invalid-block" : ""],
            },
            [
              _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-field-title",
                    attrs: { for: "cv_main_sector" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.fieldLabels.sector) +
                        "\n\t\t\t\t\t\t"
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
                _c("div", { staticClass: "wrap-form-control" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sector,
                          expression: "sector",
                        },
                      ],
                      staticClass: "form-control",
                      class: [_vm.sectorHasError ? "is-invalid" : ""],
                      attrs: { id: "cv_main_sector", name: "cv_main[sector]" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.sector = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.fieldPlaceholders.sector)),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.sectors, function (sector, code) {
                        return _c("option", { domProps: { value: code } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(sector) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group " }, [
          _c(
            "div",
            {
              staticClass: "new-job-add-block-wrap-row",
              class: [_vm.employmentHasError ? "is-invalid-block" : ""],
            },
            [
              _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-field-title",
                    attrs: { for: "cv_main_employmentType" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.fieldLabels.employment) +
                        "\n\t\t\t\t\t\t"
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
                _c("div", { staticClass: "wrap-form-control" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.employment,
                          expression: "employment",
                        },
                      ],
                      staticClass: "form-control",
                      class: [_vm.employmentHasError ? "is-invalid" : ""],
                      attrs: {
                        id: "cv_main_employmentType",
                        name: "cv_main[employmentType]",
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.employment = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.fieldPlaceholders.employment)),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.employmentTypes, function (employment, uuid) {
                        return _c("option", { domProps: { value: uuid } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(employment) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group " }, [
          _c(
            "div",
            {
              staticClass: "new-job-add-block-wrap-row",
              class: [_vm.salaryHasError ? "is-invalid-block" : ""],
            },
            [
              _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-field-title",
                    attrs: { for: "cv_main_salary" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.fieldLabels.salary) +
                        "\n\t\t\t\t\t\t"
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
                _c("div", { staticClass: "wrap-form-control" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.salary,
                          expression: "salary",
                        },
                      ],
                      staticClass: "form-control",
                      class: [_vm.salaryHasError ? "is-invalid" : ""],
                      attrs: { id: "cv_main_salary", name: "cv_main[salary]" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.salary = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.fieldLabels.salary) +
                            "\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.salaries, function (salary, uuid) {
                        return _c("option", { domProps: { value: uuid } }, [
                          _vm._v(_vm._s(salary)),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group validate autocomplete vacancy-title-block",
          },
          [
            _c(
              "div",
              {
                staticClass: "new-job-add-block-wrap-row",
                class: [_vm.contentHasError ? "is-invalid-block" : ""],
              },
              [
                _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-field-title",
                      attrs: { for: "cv_main_content" },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.fieldLabels.content) +
                          "\n\t\t\t\t\t\t"
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.content,
                        expression: "content",
                      },
                    ],
                    staticClass: "professions-typeahead form-control",
                    class: [_vm.contentHasError ? "is-invalid" : ""],
                    attrs: {
                      type: "text",
                      id: "cv_main_content",
                      name: "cv_main[content]",
                      maxlength: "1024",
                      minlength: "50",
                      placeholder: _vm.fieldLabels.content,
                    },
                    domProps: { value: _vm.content },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.content = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "invalid-feedback" }),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
            _c(
              "label",
              {
                staticClass: "form-field-title",
                attrs: { for: "cv_relocate" },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.fieldLabels.relocate) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "new-job-add-block-wrap-row-right custom-checkbox-wrapper",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.relocate,
                      expression: "relocate",
                    },
                  ],
                  staticClass: "custom-checkbox-wrapper__input",
                  attrs: { type: "checkbox", id: "cv_relocate" },
                  domProps: {
                    checked: Array.isArray(_vm.relocate)
                      ? _vm._i(_vm.relocate, null) > -1
                      : _vm.relocate,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.relocate,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.relocate = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.relocate = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.relocate = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("b", { staticClass: "custom-checkbox-wrapper__b" }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
            _c(
              "label",
              {
                staticClass: "form-field-title",
                attrs: { for: "cv_is_public" },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.fieldLabels.isPublic) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "new-job-add-block-wrap-row-right custom-checkbox-wrapper",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isPublic,
                      expression: "isPublic",
                    },
                  ],
                  staticClass: "custom-checkbox-wrapper__input",
                  attrs: { type: "checkbox", id: "cv_is_public" },
                  domProps: {
                    checked: Array.isArray(_vm.isPublic)
                      ? _vm._i(_vm.isPublic, null) > -1
                      : _vm.isPublic,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isPublic,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isPublic = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isPublic = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isPublic = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("b", { staticClass: "custom-checkbox-wrapper__b" }),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group btn-save-mob for-line-mob" }, [
          _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
            _c("div", { staticClass: "new-job-add-block-wrap-row-left" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "new-job-add-block-wrap-row-right text-right" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    class: [
                      _vm.savedMainInfo ? "btn-success" : "btn-outline-primary",
                    ],
                  },
                  [
                    _vm.savedMainInfo
                      ? _c("span", [_c("i", { staticClass: "fas fa-check" })])
                      : _c("span", [
                          _c("i", { staticClass: "fas fa-save" }),
                          _vm._v(" " + _vm._s(_vm.saveTitle)),
                        ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.savedMainInfo
          ? _c(
              "div",
              { staticClass: "alert alert-success", attrs: { role: "alert" } },
              [_vm._v("\n\t\t\tYour changes has been saved!\n\t\t")]
            )
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c(
              "div",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [_vm._v("\n\t\t\tYour changes has not been saved!\n\t\t")]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }