var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vote-wrap" }, [
    _c("p", [_vm._v(_vm._s(this.title))]),
    _vm._v(" "),
    _c("ul", [
      _c("li", [
        _c("div", { on: { click: _vm.handleLikeClick } }, [
          _c("i", {
            staticClass: "fa-thumbs-up",
            class: [_vm.isLikedByUser ? "fas" : "far"],
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(this.likeCounter))]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("div", { on: { click: _vm.handleDisLikeClick } }, [
          _c("i", {
            staticClass: "fa-thumbs-down",
            class: [_vm.isDisLikedByUser ? "fas" : "far"],
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(this.dislikeCounter))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }