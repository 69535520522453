const Quill = require('quill');

let
	textFormatButtons = ['bold', 'italic', 'underline', 'strike'],
	linkButton = ['link'];

let toolbarOptions = [
	textFormatButtons,
	['blockquote', 'code-block'],
	
	[{ 'header': 1 }, { 'header': 2 }],               // custom button values
	[{ 'list': 'ordered'}, { 'list': 'bullet' }],
	[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
	[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
	[{ 'direction': 'rtl' }],                         // text direction
	
	[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
	[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	
	[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
	[{ 'font': [] }],
	[{ 'align': [] }],
	
	['clean']                                         // remove formatting button
];

let editorContainers = document.getElementsByClassName('editor');


[].forEach.call(editorContainers, (editorContainer) => {
	let placeholder = '';

	if (editorContainer.getAttribute('data-placeholder')) {
		placeholder = editorContainer.getAttribute('data-placeholder');
	}

	let quill = new Quill('.editor', {
		theme: 'snow',
		modules: {
			toolbar: editorContainer.classList.contains('short') ? [textFormatButtons, linkButton] : toolbarOptions,
		},
		placeholder,
	});
	
	let descriptionEl = document.getElementById(editorContainer.getAttribute('data-target'));
	
	if(descriptionEl.innerText.length > 0) {
		quill.root.innerHTML = descriptionEl.innerText;
	}
	
	quill.on('text-change', function(e) {
		descriptionEl.innerHTML = quill.container.firstChild.innerHTML;
	});
	
});
