function packageChooseEvent(category, action, label) {
	/*gtag('event', action, {
		'event_category': category,
		'event_label': label
	});*/
}

let packagesListEl = document.getElementById('packages-list');

if(packagesListEl) {
	let chooseLinkEls = packagesListEl.querySelectorAll('a.btn.btn-primary');
	
	chooseLinkEls.forEach(function(choseLinkEl) {
		if(choseLinkEl.hasAttribute('data-target')) {
			choseLinkEl.addEventListener('click', function () {
				packageChooseEvent('packages', 'choose', 'package-' + choseLinkEl.getAttribute('data-target'));
			})
		}
	})
}

let paymentFormEl = document.querySelector('form[name="payment_form"]');

if(paymentFormEl) {
	let payButtonEl = paymentFormEl.querySelector('button[type="submit"]');
	
	if(payButtonEl) {
		payButtonEl.addEventListener('click', function (event) {
			packageChooseEvent('packages', 'pay', 'package-' + event.target.getAttribute('data-target'))
		})
	}
}