require('../scss/app.scss');

require('./Vue/index');

const $ = require('jquery');

require('bootstrap');
require('./Vue/index');
require('./editor');

require('./tagify');
require('slick-carousel/slick/slick.min');
require('@fortawesome/fontawesome-free/js/all');

require('./Components/Utils');
require('./Components/filterForm');
require('./Components/scrollToTop');
require('./Components/locale-switcher');

// require('./Containers/Offers/index');
require('./Containers/Account/index');
require('./Containers/Companies/index');

require('./Google/index');

require('./Containers/Layout/Header');
require('./Containers/Budget/topUpForm');

require('./dateRange');
require('./main');
require('./chooseTariffCard');

require('./paymentMethod');
require('./inputAddImg');

require('./Containers/Subscriptions/List');
require('./Containers/MainPage/searchForm');
require ('./Containers/Dashboard/offer.update');

require('./autocomplete.fields');

require('fslightbox');

require('./packagePage');
require('./Containers/PaymentMethods/switch-methods');

require('./notification-block');
require('./promoBanner');

require('./Containers/PromoPage/promoPage');
require('./Containers/Guideline/guideline');
require('./Containers/PromoBanner/promoBanner');
require('./Containers/Services/services');

require('./Containers/MainPage/Popular-questions');
require('./Containers/Header/login-reg-block');
require('./Containers/Header/lang-btn');

require('./Containers/Services/services-counter');

// require('./Containers/Account/jobsServices');

require('./Containers/Services/nav-tabs-castom-services');

require('./Containers/Services/services-dropdown');

// require('./Containers/Leads');

require('./Containers/Services/tarifs-list');
require('./Containers/MainPage/slider');

require('./Containers/MainPage/employer-reg-form');
require('./Containers/MainPage/read-more-block');
require('./Containers/Header/packages-dropdown');
require('./Containers/Registration/tooltips');
require('./Containers/Account/tooltip');

require('./Components/address.fields');
require('./Containers/Offers/editContacts');

require('./choices');
// require('./Containers/Account/selectAllVacancies');
// require('./Containers/Account/myVacanciesScroll');
require('./Components/customCheckbox');
require('./Containers/Vacancies/replyBlockSticky');

