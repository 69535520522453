const btnCancelSetting = document.querySelector('.account-btns .btn-cancel-setting');

if(btnCancelSetting){
    const formFields = document.querySelectorAll('.form-group-form-account .form-control');
    const customCheckboxes = document.querySelectorAll('.custom-checkbox .custom-control-input');
    const accountPass = document.querySelectorAll('.account-setting-pass input');
    let btnDisabled = true;

    formFields.forEach(field=> {
        field.oninput = function() {
            btnCancelSetting.disabled = false;
            btnDisabled = false;
        };
    })

    customCheckboxes.forEach(checkbox=> {
        checkbox.oninput = function() {
            btnCancelSetting.disabled = false;
            btnDisabled = false;
        };
    })

    accountPass.forEach(pass=> {
        pass.oninput = function() {
            btnCancelSetting.disabled = false;
            btnDisabled = false;
        };
    })

    btnCancelSetting.addEventListener('click', () => {
        if(!btnDisabled){
            window.location.reload(false);
        }
    });
}
