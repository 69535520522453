<template>
	<div class="reviews-footer-bottom pt-4 px-4">

		<div v-if="!this.sent && !this.pending">
			<form name="leave_comment">
				<div class="form-group">
					<label for="name" class="text-muted">{{ this.nameLabel }}</label><span class="text-danger mx-1">*</span>
					<input
							id="name"
							v-model="name"
							v-bind:placeholder="this.namePlaceholder" class="form-control"
							v-bind:class="[errors.name ? 'border-danger' : '']"
					/>
				</div>
				<div class="form-group">
					<label for="name" class="text-muted">{{ this.commentLabel }}</label><span class="text-danger mx-1">*</span>
					<textarea
							class="mb-0"
							v-model="comment"
							v-bind:placeholder="this.commentPlaceholder"
							v-bind:class="[errors.comment ? 'border-danger' : '']">
						
					</textarea>
				</div>
				<div v-if="relatedUser.length <= 0" class="custom-control custom-checkbox form-group">
					<input type="checkbox" id="anonymously" class="custom-control-input text-body" v-model="anonymously">
					<label for="anonymously" class="custom-control-label">
						{{ this.anonymouslyLabel }}
					</label>
				</div>
				
				<div>
					<button type="button" class="btn btn-success mr-3 mb-3" v-on:click="handleSubmit">
						{{ this.submitTitle }}
					</button>
					<button type="button" class="btn btn-outline-primary mb-3" v-on:click="handleCancelClick">{{ this.cancelTitle }}</button>
				</div>
				<p class="review-footer">Отзыв публикуется на сайте только после проверки модератором.</p>
			</form>
		</div>
		<div v-if="this.pending" class="text-center py-5 ">
			<i class="fa-3x fa-spin fa-spinner fas text-primary"></i>
		</div>
		<div v-if="this.sent && !this.failed" class="text-center py-5 ">
			<i class="fas fa-check fa-3x text-success"></i>
		</div>
		<div v-if="this.failed && this.sent && !this.pending" class="text-center py-5 ">
			<i class="fas fa-exclamation-circle fa-3x text-danger"></i>
			<div>
				<p class="m-0 mt-2 text-info text-underline" v-on:click="retry">{{ this.retryTitle }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "CommentReview",
		props: {
			code: String,
			submitTitle: String,
			cancelTitle: String,
			commentPlaceholder: String,
			commentLabel: String,
			namePlaceholder: String,
			nameLabel: String,
			retryTitle: String,
			anonymouslyLabel: String,
			relatedUser: String
		},
		data() {
			return {
				comment: '',
				name: '',
				anonymously: '',
				sent: false,
				pending: false,
				failed: false,
				errors: {
					name: false,
					comment: false,
				},
			}
		},
		mounted() {
		},
		methods: {
			handleSubmit() {
				let self = this;
				
				if(!this.checkForm()) {
					return;
				}
				
				self.pending = true;
				
				axios.post('/api/review/' + this.code + '/comment', {
					comment: this.comment,
					name: this.name,
					anonymously: this.anonymously
				})
					.then(function (response) {
						if (response.status === 200) {
							self.pending = false;
							self.sent = true;
						}
					})
					.catch(function (error) {
						console.log(error);
						self.pending = false;
						self.sent = true;
						self.failed = true;
					});
			},
			retry() {
				this.sent = false;
				this.failed = false;
				this.pending = false;
			},
			checkForm: function () {
				let self = this;
				
				if (self.name && self.comment) {
					self.errors.name = false;
					self.errors.comment = false;
					return true;
				}
				
				self.errors.name = !this.name;
				self.errors.comment = !this.comment;
				
				return false;
			},
			handleCancelClick(e) {
				e.target.parentNode.parentNode.parentNode.parentNode.parentNode.classList.toggle('footer-active');
			}
		}
	}
</script>

<style scoped>

</style>