let accountFormEl = document.getElementsByClassName('account'),
	phoneFieldEl = (accountFormEl && accountFormEl.length > 0) ? accountFormEl[0].querySelector('[name="phone"]') : null;

function setRequired(contactBy) {
	phoneFieldEl.required = contactBy === '1' || contactBy === '2';
}

if(accountFormEl && accountFormEl.length > 0) {
	let contactByEls = accountFormEl[0].querySelectorAll('[name="contactBy"]');
	
	if(contactByEls && contactByEls.length > 0) {
		
		setRequired(accountFormEl[0].querySelectorAll('[name="contactBy"]:checked')[0].value);
		
		Array.from(contactByEls).forEach(function(value, index, array) {
			value.addEventListener('change', function(event) {
				let contactBy = event.target.value;
				setRequired(contactBy);
			})
		});
	}
}