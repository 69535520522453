<template>
	<ul class="new-job-add-sitebar">
		<li class="active-item">
			<a href="#blockMain">
				<div>
					{{ mainBlockTitle }}
				</div>
			</a>
		</li>
		<li>
			<a href="#blockExperience">
				<div>
					{{ experienceBlockTitle }}
				</div>
			</a>
		</li>
		
		<li>
			<a href="#blockEducation">
				<div>
					{{ educationBlockTitle }}
				</div>
			</a>
		</li>
<!--		<li>
			<a href="#blockAttachedFiles">
				<div>
					{% trans from 'offer' %}blocks.attachments.title{% endtrans %}
				</div>
			</a>
		</li>-->
	</ul>
</template>

<script>
	export default {
		name: "FormSidebar",
		props: {
			'mainBlockTitle': String,
			'experienceBlockTitle': String,
			'educationBlockTitle': String
		}
	}
</script>

<style scoped>

</style>