const Choices = require('choices.js');

let choicesParams = {
	placeholderValue: '',
	searchPlaceholderValue: '',
	removeItemButton: true,
	placeholder: true,
	classNames: {
		itemSelectable: 'choices__item--selectable bg-primary rounded-0',
		button: 'choices__button border-white',
		itemChoice: 'bg-white'
	}
};

document.querySelectorAll('.multi-select').forEach(function(item, key) {
	new Choices(item, choicesParams);
});