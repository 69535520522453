let addressBlockEl = document.getElementById('address-block');

if(addressBlockEl) {
	let countryInputEl = addressBlockEl.querySelector('select[name="address[country]"]'),
	    countryBlock = countryInputEl.parentNode.parentNode,
		cityInputEl = addressBlockEl.querySelector('input[name="address[city]"]'),
	    cityBlock = cityInputEl.parentNode.parentNode,
		streetInputEl = addressBlockEl.querySelector('input[name="address[street]"]'),
	    streetBlock = streetInputEl.parentNode.parentNode,
	    postalInputEl = addressBlockEl.querySelector('input[name="address[postal]"]'),
	    postalBlock = postalInputEl.parentNode.parentNode
	;
	
	if(countryInputEl.value.length > 0) {
		showFields([countryBlock])
	}
	
	if(cityInputEl.value.length > 0) {
		showFields([cityBlock])
	}
	
	if(streetInputEl.value.length > 0) {
		showFields([streetBlock])
	}
	
	if(postalInputEl.value.length > 0) {
		showFields([postalBlock])
	}
	
	countryInputEl.addEventListener('change', function(event) {
		
		if(event.target.value.length > 0) {
			showFields([cityBlock])
		} else {
			hideFields([cityBlock, streetBlock, postalBlock]);
			clearElementsValue([cityInputEl, streetInputEl, postalInputEl]);
		}
	});

	cityInputEl.addEventListener('keyup', function(event) {
		if(event.target.value.length > 0) {
			showFields([streetBlock])
		} else {
			hideFields([streetBlock, postalBlock]);
			clearElementsValue([streetInputEl, postalInputEl]);
		}
	});
	
	streetInputEl.addEventListener('keyup', function(event) {
		if(event.target.value.length > 0) {
			showFields([postalBlock])
		} else {
			hideFields([postalBlock]);
			clearElementsValue([postalInputEl]);
		}
	});
	
	postalInputEl.addEventListener('keyup', function (event) {
		event.target.value = event.target.value.replace(/[^0-9]/g, '')
	})
	
	postalInputEl.addEventListener('focusout', function (event) {
		event.target.value = event.target.value.replace(/[^0-9]/g, '')
	})
}

function showFields(elements) {
	elements.forEach(function(element) {
		if(element.classList.contains('d-none')) {
			element.classList.remove('d-none');
		}
	})

}

function hideFields(elements) {
	elements.forEach(function(element) {
		if(!element.classList.contains('d-none')) {
			element.classList.add('d-none');
		}
	});
}

function clearElementsValue(elements) {
	elements.forEach(function(element) {
		element.value = '';
	})
}