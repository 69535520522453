const accountEmployersBtns = document.querySelectorAll('.account-employers-btn');

if (accountEmployersBtns) {
    let height = 0;

    accountEmployersBtns.forEach(btn => {
        if(btn.offsetHeight > height) {
            height = btn.offsetHeight;
        }
    });

    accountEmployersBtns.forEach(btn => {
            btn.setAttribute("style",`height:${height}px`);
    });
}