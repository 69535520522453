const navTabs = document.querySelector('.nav-tabs-castom-services');

function offset(el) {
    const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

if(navTabs) {
    const navTabsT0Top = offset(navTabs).top - 52;

    window.addEventListener('scroll', function () {
        if (pageYOffset > navTabsT0Top) {
            navTabs.classList.add('nav-tabs-castom-services-fixed');
        } else if (pageYOffset < navTabsT0Top){
            navTabs.classList.remove('nav-tabs-castom-services-fixed');
        }
    });

/*    let items = navTabs.querySelectorAll('.nav-link');
    let servicesSubtitles = document.querySelectorAll('.block-services-subtitles .jobs-tab-content');

    items.forEach((btn, i) => {
        btn.addEventListener('click', (e) => {
            const dataTab = btn.getAttribute("href").slice(1);

            servicesSubtitles.forEach(subtitle => {
                if(subtitle.getAttribute("data-services-tab") == dataTab) {
                    servicesSubtitles[i].classList.remove('d-none');
                } else {
                    subtitle.classList.add('d-none');
                }
            });
        });
    });*/
}