const menuIcon = document.querySelector('.guideline-wrap .menu-icon');
const menu = document.querySelector('.guideline-wrap .nav-tabs');
const bodyClass = 'menu-active';
let show = false;
const timeout = 300;

if (menuIcon) {
	menuIcon.addEventListener('click', function () {
		show = !show;
		if (show) return showMenu();
		return closeMenu();
	}, false);

	document.addEventListener('click', e => {
		let target = e.target;
		let its_menu = target === menu || menu.contains(target);
		let its_hamburger = target === menuIcon;
		let menu_is_active = menu.classList.contains('menu-active');

		if (!its_menu && !its_hamburger && menu_is_active) {
			menuIcon.dispatchEvent(event);
		}
	});
}

function showMenu() {
	menu.style.display = 'flex';

	setTimeout(function() {
		menu.classList.add(bodyClass);
	}, 0);
}

function closeMenu() {
	menu.classList.remove(bodyClass);

	setTimeout(function() {
		menu.style.display = 'none';
	}, timeout);
}

const guidelineItems = document.querySelectorAll(".guideline-wrap .nav-item");
const event = new Event('click');

if (guidelineItems) {
	guidelineItems.forEach(item => {
		item.addEventListener('click', () => {
			let menu_is_active = menu.classList.contains('menu-active');
			if(menu_is_active){
				menuIcon.dispatchEvent(event);
			}
		}, false);
	});
}

window.addEventListener('resize', reportWindowSize);

function reportWindowSize() {
	if(window.innerWidth > 991 && menu){
		menu.style.display = 'flex';
	}
}