<template>
	<div class="work-experience-block block-add-new-work-experience">
		
		<p class="new-job-add-block-wrap-title" id="blockExperience" v-if="experiences.length > 0 || showForm">
			{{ labels.title }}
		</p>
		
		<div class="callout callout-danger bg-light p-3" v-if="showErrorCallout">
			<span>Problem occurred while saving your data.</span>
		</div>
		
		<!--    Experiences list -->
		
		<div v-if="experience.company && experience.position" v-for="(experience, index) in experiences"
		     class="work-experience-result-block">
			<div class="form-group mb-0">
				<div class="new-job-add-block-wrap-row">
					<div class="new-job-add-block-wrap-row-left"></div>
					<div class="new-job-add-block-wrap-row-right">
						<div class="work-experience-result-wrap mb-3"
						     v-bind:class="[index === currentExperienceIndex ? 'edit-block' : '']">
							<div class="work-experience-result-btns">
								<i class="fas fa-pen" v-on:click="editExperience(index)"></i>
								<i class="fas fa-times" v-on:click="removeExperience(index)"></i>
							</div>
							<p class="work-experience-result-title">{{ experience.company }}</p>
							
							<p class="work-experience-result-autor">{{ experience.position }}</p>
							
							<p class="work-experience-result-description">{{ experience.description }}</p>
							
							<p class="work-experience-result-date" v-if="experience.startDate">
								<span>{{ experience.startDate }}</span>
								&ndash;
								<span v-if="experience.endDate">
									{{ experience.endDate }}
								</span>
								<span v-else>
									Still working
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--    .Experiences list -->
		
		<!--    Form -->
		<div v-if="showForm">
			<form novalidate @submit="saveExperience">
				<div class="form-group">
					<div class="new-job-add-block-wrap-row"
					     v-bind:class="[hasError('companyName') ? 'is-invalid-block' : '']">
						<div class="new-job-add-block-wrap-row-left">
							<label class="mb-2" for="experience_companyName">
								{{ labels.company }}
							</label>
						</div>
						<div class="new-job-add-block-wrap-row-right">
							<input type="text" id="experience_companyName" name="experience[companyName]"
							       maxlength="1024"
							       class="form-control" v-model="companyName"
							       v-bind:class="[hasError('companyName') ? 'is-invalid' : '']">
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="new-job-add-block-wrap-row"
					     v-bind:class="[hasError('position') ? 'is-invalid-block' : '']">
						<div class="new-job-add-block-wrap-row-left">
							<label class="mb-2" for="experience_position">
								{{ labels.position }}
							</label>
						</div>
						<div class="new-job-add-block-wrap-row-right">
							<input type="text" id="experience_position" name="experience[position]" maxlength="1024"
							       class="form-control" v-model="position"
							       v-bind:class="[hasError('position') ? 'is-invalid' : '']">
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="new-job-add-block-wrap-row">
						<div class="new-job-add-block-wrap-row-left">
							<label class="mb-2" for="experience_description">
								{{ labels.description }}
							</label>
						</div>
						<div class="new-job-add-block-wrap-row-right">
						<textarea id="experience_description" name="experience[description]" class="form-control"
						          v-model="description"></textarea>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="new-job-add-block-wrap-row"
					     v-bind:class="[hasError('startDate') ? 'is-invalid-block' : '']">
						<div class="new-job-add-block-wrap-row-left">
							<label class="mb-2" for="experience_startDateMonth">
								{{ labels.startDate }}
							</label>
						</div>
						<div class="new-job-add-block-wrap-row-right">
							<div class="block-month-year">
								<div class="wrap-form-lang-select">
									<select id="experience_startDateMonth" name="experience[startDateMonth]"
									        class="form-control" data-field="exp_startDateMonth"
									        v-model="startDateMonth"
									        v-bind:class="[hasError('startDate') ? 'is-invalid' : '']">
										<option disabled value="">
											{{ labels.startMonth }}
										</option>
										
										<option v-for="(month, index) in monthsList" :value="index">
											{{ month }}
										</option>
									</select>
								</div>
								<input type="number" id="experience_startDateYear" name="experience[startDateYear]"
								       class="form-control work-experience-year" v-model="startDateYear" min="1971"
								       :max='new Date().getFullYear()'>
							</div>
							<div class="custom-checkbox-wrapper">
								<input type="checkbox" class="custom-checkbox-wrapper__input" id="experience_stillWorking"
									   name="stillWorking"
									   v-model="stillWorking">
								<b class="custom-checkbox-wrapper__b"></b>
							</div>
							<label for="experience_stillWorking"
							       class="text-normal mb-0">
								{{ labels.stillWorking }}
							</label>
						</div>
					</div>
				</div>
				<div class="form-group mb-0" v-if="!stillWorking">
					<div class="new-job-add-block-wrap-row"
					     v-bind:class="[hasError('endDate') ? 'is-invalid-block' : '']">
						<div class="new-job-add-block-wrap-row-left">
							<label class="mb-2" for="experience_endDateMonth">
								{{ labels.endDate }}
							</label>
						</div>
						<div class="new-job-add-block-wrap-row-right">
							<div class="block-month-year">
								<div class="wrap-form-lang-select">
									<select id="experience_endDateMonth" name="experience[endDateMonth]"
									        class="form-control" data-field="exp_endDateMonth"
									        v-model="endDateMonth"
									        v-bind:class="[hasError('endDate') ? 'is-invalid' : '']">
										<option disabled value="">{{ labels.endMonth }}</option>
										
										<option v-for="(month, index) in monthsList" :value="index">
											{{ month }}
										</option>
									
									</select>
								</div>
								<input type="number" id="experience_endDateYear" name="experience[endDateYear]"
								       v-on:keyup="checkYear"
								       class="form-control work-experience-year" v-model="endDateYear"
								       :max='new Date().getFullYear()' :min="startDateYear"
								       v-bind:class="[hasError('endDate') ? 'is-invalid' : '']">
							</div>
						</div>
					</div>
				</div>
				<div class="form-group mb-3">
					<div class="new-job-add-block-wrap-row">
						<div class="new-job-add-block-wrap-row-left"></div>
						<div class="new-job-add-block-wrap-row-right for-line-mob">
							<button
									type="button"
									class="btn"
									:class="[!companyName && !position ? 'btn-outline-success' : 'btn-outline-success']"
									v-on:click="saveExperience">
								{{ labels.save }}
							</button>
							<button type="button" class="btn btn-outline-grey ml-4" v-on:click="cancelClick">
								{{ labels.cancel }}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<!-- .Form -->
		
		<div class="form-group mb-0" v-if="!showForm">
			<div class="new-job-add-block-wrap-row new-job-add-block-wrap-row__flex"
			     v-bind:class="{ 'd-none' : experiences.length > 0 }">
				<div class="new-job-add-block-wrap-row-left">
					<p class="additional-information-block-title" v-if="!showForm" :disabled="!code">
						<span v-if="!showLoader">{{ labels.addTitle }}</span>
						<i v-if="showLoader" class="fa fa-fw fa-spin fa-spinner"></i>
					</p>
				</div>
				<div class="new-job-add-block-wrap-row-right text-right">
					<button class="btn-show-additional-information-block" type="button" v-on:click="addExperience"
					        v-if="!showForm"
					        :disabled="!code">
						<i class="fas fa-chevron-down"></i>
					</button>
				</div>
			</div>
			<div class="text-right mb-3" v-if="experiences.length > 0">
				<button type="button"
				        class="btn btn-outline-primary"
				        v-if="!showForm"
				        v-on:click="addExperience">
					{{ labels.addTitle }}
				</button>
			</div>
		</div>
	</div>

</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "Experiences",
		data() {
			return {
                experiences: [],
                'formHtml': '',
				'showForm': false,
				'isInvalid': false,
				'companyName': '',
				'position': '',
				'description': '',
				'startDateMonth': 0,
				'startDateYear': '',
				'endDateMonth': 0,
				'endDateYear': '',
				'stillWorking': true,
				'experience': null,
				'currentExperienceIndex': null,
				errors: [],
				monthsList: [],
				showErrorCallout: false,
				editBlockId: null,
				currentYear: new Date().getFullYear(),
				showLoader: false,
			}
		},
		props: {
			code: String,
			months: String,
			labels: Object
		},
		mounted() {
			this.monthsList = this.months.split(',');
			
			this.getExperiences();
		},
		
		watch: {
			startDateYear: function (value) {
				
				let self = this;
				
				if (value.length > 4) {
					self.startDateYear = value.slice(0, -1);
				}
				
				if ((value.length === 4 && parseInt(value) < 1900) || (parseInt(value) > self.currentYear)) {
					self.startDateYear = self.currentYear;
				}
			},
			endDateYear: function (value) {
				
				let self = this;
				
				if (value.length > 4) {
					self.endDateYear = value.slice(0, -1);
				}
				
				if ((value.length === 4 && parseInt(value) < 1900) || (parseInt(value) > self.currentYear)) {
					self.endDateYear = self.currentYear;
				}
			}
		},
		methods: {
			getExperiences() {
				let self = this;
				
				if (self.code) {
					
					self.showLoader = true;
					
					axios.get('/api/cv/' + self.code + '/experiences')
						.then(function (response) {
							self.experiences = response.data;
							
							self.showLoader = false;
						})
				}
			},
			
			addExperience() {
				let self = this;
				self.showForm = true;
			},
			saveExperience() {
				
				let self = this;
				
				self.errors = [];
				
				self.validateForm();
				
				if (!this.errors.length) {
					let postData = {
						cv: self.code,
						company: self.companyName,
						position: self.position,
						description: self.description,
						startDate: !isNaN(parseInt(self.startDateMonth)) ? ((parseInt(self.startDateMonth) + 1) + '-' + self.startDateYear) : null,
						endDate: (self.endDateMonth > 0 && self.endDateYear.length > 0) ? ((parseInt(self.endDateMonth) + 1) + '-' + self.endDateYear) : null,
						experience: self.experience
					};
					
					axios.post('/api/cv/save-experience', {
						'cv_experience': postData
					}).then(function (response) {
						postData.id = response.data.experience;
						
						self.getExperiences();
						
						self.currentExperienceIndex = null;
						self.clearFields();
						
						self.showForm = false;
						self.showErrorCallout = false;
					})
						.catch(function (response) {
							self.showForm = true;
							self.showErrorCallout = true;
						});
				}
			},
			
			validateForm() {
				let self = this;
				
				if (!self.companyName) {
					self.errors.push('companyName');
				}
				
				if (!self.position) {
					self.errors.push('position');
				}
				
				if (parseInt(self.startDateYear) > 0 && parseInt(self.endDateYear) > 0) {
					/*  years are filled */
					
					if (parseInt(self.startDateYear) > parseInt(self.endDateYear)) {
						self.errors.push('endDate');
					}
				}
				
				if (self.endDateYear > 0 && isNaN(parseInt(self.startDateYear))) {
					/*  start year is missing */
					
					self.errors.push('startDate');
				}
				
				if (self.startDateYear > new Date().getFullYear()) {
					self.errors.push('startDate');
				}
				
				if (self.endDateYear > new Date().getFullYear()) {
					self.errors.push('endDate');
				}
				
				if (parseInt(self.startDateMonth) < 0 || parseInt(self.startDateMonth) > 11) {
					self.errors.push('startDate');
				}
				
				if (parseInt(self.endDateMonth) < 0 || parseInt(self.endDateMonth) > 11) {
					self.errors.push('endDate');
				}
			},
			editExperience(index) {
				let self = this;
				
				self.currentExperienceIndex = index;
				
				self.companyName = self.experiences[index]['company'];
				self.position = self.experiences[index]['position'];
				self.description = self.experiences[index]['description'];
				
				let startDateParts = self.experiences[index]['startDate'] ? self.experiences[index]['startDate'].split(' ') : null,
					endDateParts = self.experiences[index]['endDate'] ? self.experiences[index]['endDate'].split(' ') : null;
				
				if (startDateParts) {
					self.startDateMonth = parseInt(startDateParts[0]) - 1;
					self.startDateYear = parseInt(startDateParts[1]);
				} else {
					self.startDateYear = self.currentYear;
				}
				
				if (endDateParts) {
					self.endDateMonth = parseInt(endDateParts[0]) - 1;
					self.endDateYear = parseInt(endDateParts[1]);
				} else {
					self.stillWorking = true;
				}
				
				self.editBlockId = index;
				self.showForm = true;
				self.experience = self.experiences[index]['id']
			},
			removeExperience(index) {
				let self = this;
				
				if (self.experiences[index]) {
					axios.post('/api/cv/remove-experience', {
						'cv_experience': {
							id: self.experiences[index]['id'],
							cv: self.code
						}
					}).then(function () {
						self.getExperiences();
					})
				} else {
					console.log('Experience not found')
				}
			},
			clearFields() {
				let self = this;
				
				self.companyName = '';
				self.position = '';
				self.description = '';
				
				self.startDateMonth = '';
				self.startDateYear = '';
				
				self.endDateMonth = '';
				self.endDateYear = '';
				self.stillWorking = false;
				
				self.experience = null;
			},
			hasError(fieldKey) {
				let self = this;
				
				return self.errors.includes(fieldKey);
			},
			cancelClick: function () {
				let self = this;
				
				self.showForm = false;
				self.currentExperienceIndex = null;
				self.clearFields();
			},
			checkYear: function (event) {
				let value = event.target.value;
				
				let self = this;
				
				if (String(value) > 4) {
					return false;
				}
			}
		}
	}
</script>

<style scoped>

</style>