// import ImagePreview from "../../ImagePreview";

// let imageInputEl = document.getElementById('logo');

// if(imageInputEl) {
// 	imageInputEl.onchange = function() {
// 		if(this.files.length > 0) {
// 			let acceptedMimeTypes = [
// 				"image/jpeg",
// 				"image/png",
// 			];
			
// 			if((this.files[0].size / 1000 / 1000) > 4) {
// 				alert('Max 4MB');
// 				this.value = '';
// 				return;
// 			}
			
// 			if(!acceptedMimeTypes.includes(this.files[0].type)) {
// 				alert('.JPG, .JPEG, .PNG');
// 				this.value = '';
// 				return;
// 			}
// 		}
// 	};
// }