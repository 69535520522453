const activeTariff = document.querySelector('.is-active-tariff');
const notActiveTariff = document.querySelector('.not-active-tariff');

if (notActiveTariff){
	notActiveTariff.addEventListener('mouseover', () => {
		activeTariff.classList.remove('show-line');
		notActiveTariff.classList.add('show-line');
	});
	notActiveTariff.addEventListener('mouseout', () => {
		activeTariff.classList.add('show-line');
		notActiveTariff.classList.remove('show-line');
	});
}