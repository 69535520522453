var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.code
    ? _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-sm",
            class: [_vm.favored ? "btn-info" : "btn-outline-info"],
            on: { click: _vm.favor },
          },
          [
            _c("i", {
              staticClass: "fa-heart",
              class: [_vm.favored ? "fas" : "far"],
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.buttonLabel))]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }