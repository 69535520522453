scrollToTopButton = document.getElementById("scrollToTop");

// When the user scrolls down 20px from the top of the document, show the button
if(scrollToTopButton) {
	window.onscroll = function () {
		scrollFunction()
	};
}

function scrollFunction() {
	if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
		scrollToTopButton.style.display = "block";
	} else {
		scrollToTopButton.style.display = "none";
	}
}

if(scrollToTopButton) {
	scrollToTopButton.addEventListener('click', function () {
		topFunction();
	});
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
	document.body.scrollTop = 0; // For Safari
	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}