var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "new-job-add-sitebar" }, [
    _c("li", { staticClass: "active-item" }, [
      _c("a", { attrs: { href: "#blockMain" } }, [
        _c("div", [
          _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.mainBlockTitle) + "\n\t\t\t\t"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("li", [
      _c("a", { attrs: { href: "#blockExperience" } }, [
        _c("div", [
          _vm._v(
            "\n\t\t\t\t\t" + _vm._s(_vm.experienceBlockTitle) + "\n\t\t\t\t"
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("li", [
      _c("a", { attrs: { href: "#blockEducation" } }, [
        _c("div", [
          _vm._v(
            "\n\t\t\t\t\t" + _vm._s(_vm.educationBlockTitle) + "\n\t\t\t\t"
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }