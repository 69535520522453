<template>
	<div data-position="block6" class="new-job-add-block-wrap">
		<p id="blockAdditional" class="new-job-add-block-wrap-title">
			Additional information
		</p>
		<div class="form-group ">
			<div class="new-job-add-block-wrap-row">
				<div class="new-job-add-block-wrap-row-left"><p class="text-semibold on-mob-text-normal mb-3 mb-lg-0">
					Links </p></div>
				<div class="new-job-add-block-wrap-row-right block-site-video-links">
					<div class="block-sitelinks "><p onclick="this.parentNode.classList.toggle('add-link')"><i
							class="fas fa-link"></i> Add a link to your website </p>
						<div class="form-group mt-3 mb-3 mb-lg-0">
							<input type="text" id="cv_form_webLink"
							       name="cv_form[webLink]" inputmode="url"
							       placeholder="Link to website"
							       class="form-control">
						</div>
					</div>
					<div class="block-sitelinks ">
						<p onclick="this.parentNode.classList.toggle('add-link')"><i
								class="fas fa-video"></i> Add a link to a video </p>
						<div class="form-group mt-3 mb-3 mb-lg-0">
							<input type="text" id="cv_form_videoUrl"
							       name="cv_form[videoUrl]" inputmode="url"
							       placeholder="offer.https://www.youtube.com/watch?v=video_id"
							       class="form-control"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-groupb-3 mb-lg-0">
			<div class="new-job-add-block-wrap-row">
				<div class="new-job-add-block-wrap-row-left">
					<label for="cv_form_content" class="mb-2">
						CV content description
					</label>
				</div>
				<div class="new-job-add-block-wrap-row-right">
					<textarea id="cv_form_content" name="cv_form[content]"
					          rows="10"
					          placeholder="offer.form.content.placeholder"
					          class="form-control mt-0 mt-md-3"></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AdditionalInfo"
	}
</script>

<style scoped>

</style>