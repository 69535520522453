const btnCloseBannerBlock = document.querySelector('.icon-close-banner');
const bannerBlock = document.querySelector('.block-promo-banner');

document.addEventListener('DOMContentLoaded', function(){
	if (sessionStorage.getItem("promoBannerClosed")) {
		bannerBlock.classList.add('d-none');
	}
});

if(btnCloseBannerBlock) {
	btnCloseBannerBlock.addEventListener('click', e => {
		sessionStorage.setItem("promoBannerClosed", true)
		bannerBlock.classList.add('d-none')
	});
}