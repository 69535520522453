var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "new-job-add-page" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", { staticClass: "offer-title" }, [
          _vm._v("\n\t\t\t\t" + _vm._s(_vm.getPageTitle) + "\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "new-job-add-page-content" }, [
          _c("div", { staticClass: "new-job-add-page-content-left" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "block-content-filter new-cv-add-page" },
                [
                  _c("div", { staticClass: "left-part w-100" }, [
                    _c("p", { staticClass: "add-offer-desc" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.pageSubHeader) +
                          "\n\t\t\t\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "new-job-add-block-wrap",
                        attrs: { "data-position": "block1" },
                      },
                      [
                        _c("cv-main-form", {
                          attrs: {
                            labels: {
                              title: _vm.titleLabel,
                              location: _vm.locationLabel,
                              postingIn: _vm.postingInLabel,
                              sector: _vm.sectorLabel,
                              employment: _vm.employmentLabel,
                              salary: _vm.salaryLabel,
                              content: _vm.contentLabel,
                              relocate: _vm.relocateLabel,
                              isPublic: _vm.isPublicLabel,
                            },
                            placeholders: {
                              location: _vm.locationPlaceholder,
                              postingIn: _vm.postingInPlaceholder,
                              sector: _vm.sectorPlaceholder,
                              employment: _vm.employmentPlaceholder,
                            },
                            "code-prop": _vm.getShortCode,
                            "main-title": _vm.mainTitle,
                            "save-title": _vm.saveTitle,
                            "languages-string": _vm.languagesString,
                          },
                          on: { submit: _vm.onSave },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "additional-information-title",
                        class: {
                          "additional-information-title__active": _vm.code,
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.completeYourCvText) +
                            "\n\t\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "new-job-add-block-wrap new-job-add-block-wrap__mob",
                        class: { "new-job-add-block-wrap__active": _vm.code },
                        attrs: { "data-position": "block2" },
                      },
                      [
                        _c("experiences", {
                          attrs: {
                            labels: {
                              company: _vm.expCompanyNameTitle,
                              position: _vm.expPositionTitle,
                              description: _vm.expDescriptionTitle,
                              startDate: _vm.expStartDateTitle,
                              endDate: _vm.expEndDateTitle,
                              startMonth: _vm.expStartDateMonthTitle,
                              endMonth: _vm.expEndDateMonthTitle,
                              stillWorking: _vm.expStillWorkingTitle,
                              save: _vm.saveTitle,
                              cancel: _vm.cancelTitle,
                              addTitle: _vm.expAddTitle,
                              title: _vm.expTitle,
                            },
                            code: _vm.code,
                            months: _vm.months,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "new-job-add-block-wrap new-job-add-block-wrap__mob ",
                        class: { "new-job-add-block-wrap__active": _vm.code },
                        attrs: { "data-position": "block3" },
                      },
                      [
                        _c("education", {
                          attrs: {
                            cv: _vm.code,
                            labels: {
                              addLanguage: _vm.eduAddLanguageLabel,
                              educationLevel: _vm.eduLevelLabel,
                              languageLevel: _vm.eduLanguageLevelLabel,
                              save: _vm.saveTitle,
                              cancel: _vm.cancelTitle,
                              title: _vm.eduTitle,
                              addTitle: _vm.eduAddTitle,
                            },
                            placeholders: {
                              educationLevel: _vm.eduLevelPlaceholder,
                              languageLevel: _vm.eduLanguageLevelPlaceholder,
                            },
                            "languages-string": _vm.languagesString,
                            "language-levels-string": _vm.languageLevelsString,
                            "education-levels-string":
                              _vm.educationLevelsString,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "new-job-add-block-wrap new-job-add-block-wrap__mob",
                        class: { "new-job-add-block-wrap__active": _vm.code },
                      },
                      [
                        _c("attachments", {
                          attrs: {
                            cv: _vm.code,
                            "save-title": _vm.saveTitle,
                            "attachments-title": _vm.attachmentsTitle,
                            "uploads-doc-label": _vm.uploadsDocLabel,
                            "uploads-gallery-label": _vm.uploadsGalleryLabel,
                            "uploads-doc-placeholder":
                              _vm.uploadsDocPlaceholder,
                            "uploads-add-title": _vm.uploadsAddTitle,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right" }, [
                      _vm.showStatus
                        ? _c(
                            "div",
                            {
                              staticClass: "alert alert-success",
                              attrs: { role: "alert" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.responseStatus) +
                                  "\n\t\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.code
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-dark",
                              attrs: { type: "button" },
                              on: { click: _vm.onDraft },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\tDraft\n\t\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.code
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-success",
                              attrs: { type: "button" },
                              on: { click: _vm.onPublish },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\tPublish\n\t\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.code
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-info",
                              attrs: { href: "/cv/" + _vm.getShortCode },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\tPreview\n\t\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }