export const getTranslations = function() {
	let translationsElements = document.querySelectorAll('span[data-role="translations"]'),
		translations = [];
	
	if(translationsElements && translationsElements.length > 0) {
		translationsElements.forEach(function (translationElement) {
			translations[translationElement.getAttribute('data-target')] = translationElement.innerText;
		});
	}

	return translations;
};
