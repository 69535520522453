let customControls = document.querySelectorAll('.custom-control-input');

if (customControls) {
    checkControlPhone();
    customControls.forEach(btn => {
        btn.addEventListener('click', function (e) {
            checkCheckbox(btn, 'contactByEmail', '#contactByPhone');
            checkCheckbox(btn, 'contactByPhone', '#contactByEmail');
            checkControlPhone();
        })
    })
}

function checkCheckbox(checkbox, id, adjacentElement) {
    if (checkbox.getAttribute('id') === id && !checkbox.checked) {
        let el = document.querySelector(adjacentElement);
        el.checked = true;
    }
}

function checkControlPhone() {
    let phoneField = document.querySelector('.form-account-data #phone');
    let phoneCheckbox = document.querySelector('#contactByPhone');
    if (phoneCheckbox && phoneCheckbox.checked) {
        phoneField.setAttribute("required", "required");
    } else if (phoneCheckbox && !phoneCheckbox.checked) {
        phoneField.setAttribute("required", "false");
    }
}
