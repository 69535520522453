const
	flatpickr = require('flatpickr/dist/flatpickr.min'),
	monthSelectPlugin = require('flatpickr/dist/plugins/monthSelect');

const dateInputElement = document.querySelector('input[name="dates"]');

if(dateInputElement) {
	let picker = flatpickr(dateInputElement, {
		mode: "range",
		// minDate: "today",
		dateFormat: "Y-m-d",
		onChange: function(selectedDates, dateStr, instance) {
			instance.element.value = dateStr.replace('to', '-');
		}
	});

}
