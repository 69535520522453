const regRadioDesc = document.querySelectorAll(".employer-reg__desc .castome-radio-btn");
const regRadioMob = document.querySelectorAll(".employer-reg__mob .castome-radio-btn");

if (regRadioDesc.length > 0) {
    regRadioDesc[0].checked = true;
}

if (regRadioMob.length > 0) {
    regRadioMob[0].checked = true;
}
