import * as filters from '../../Components/filterForm'

let filterCompanyFormEl = document.getElementById('companies-filter'),
	locationsListEl = null,
	locationsEl = null;

if(filterCompanyFormEl) {
	locationsListEl = filterCompanyFormEl.querySelector('[name="locationsList[]"]'),
		locationsEl = filterCompanyFormEl.querySelector('[name="locations"]');

	filters.enableChoicesSelect(filterCompanyFormEl);
	filters.handleChoices(filterCompanyFormEl);
	filters.handleApply(filterCompanyFormEl);
}
