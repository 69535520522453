let tariffsNames = document.querySelectorAll('.rates-list-content .card .package-name.tariff-name');

if (tariffsNames) {
    tariffsNames.forEach(name => {
        name.addEventListener('click', () => {
            tariffsNames.forEach(n => {
                n.parentNode.parentNode.parentNode.classList.remove('card-header__open');
            })

            setTimeout(function () {
                const parentBlock = name.parentNode.parentNode.parentNode.parentNode;
                if (parentBlock.querySelector('.tariff-card-detail').classList.contains('show')) {
                    name.parentNode.parentNode.parentNode.classList.add('card-header__open');
                }
            }, 365);
        });
    })
}
