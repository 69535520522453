'use strict';

import Vue from 'vue';

import Favorites from './components/offers/Favorites'
import ReplyForm from './components/replies/ReplyForm';
import ReplyToggle from './components/replies/ReplyToggle';
import Broadcast from './components/messages/Broadcast';
import Vote from './components/reviews/Vote'
import CommentReview from './components/reviews/CommentReview'
import CommentsList from './components/reviews/CommentsList';
import CvForm from "./components/offers/CvForm";
import Notifications from "./components/messages/Notifications";
import Jobs from "./components/account/jobs/Jobs"
import Pagination from "./components/pagination/Pagination";
import Subscriptions from "./components/account/subscriptions/Subscriptions"

import VueToast from 'vue-toast-notification';

Vue.config.productionTip = false;

Vue.use(VueToast, {
		duration: 5000,
});

new Vue({
	el: '#app',
	delimiters: ['${', '}'],
	components: {
		Favorites,
		ReplyForm,
		ReplyToggle,
		Broadcast,
		Vote,
		CommentReview,
		CommentsList, CvForm, Notifications,
		Jobs,
		Pagination,
		Subscriptions,
		VueToast,
	},
	data: {}
});