	<template>
	<div class="position-fixed text-center w-100" style="bottom: 0; z-index: 1000">
		<div v-for="message in messages" class="py-3 broadcast-message-container"
		     v-bind:style="{backgroundColor: '#' + message.bgColor, color : '#' + message.textColor}">

			<p v-on:click="messageToggle" class="broadcast-message-text broadcast-message-text-expand" >
				<i class="fas pr-1 text-warning" v-bind:class="[icon[message.type]]"></i>
				{{message.message}}
			</p>

		</div>
	</div>
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "Broadcast",
		data() {
			return {
				messages: [],
				stylesObject: {},
				icon: {
					'default' : 'fa-bell',
					'warning' : 'fa-exclamation-triangle',
					'info' : 'fa-info-circle',
					'danger' : 'fa-exclamation-circle'
				},
			}
		},
		methods: {
			messageToggle(event) {
				event.target.classList.toggle('broadcast-message-text');
			}
		},
		mounted() {
			let self = this;
			
			axios.get('/api/messages/broadcast')
				.then(function (response) {
					self.messages = response.data;
				})
		},
	}
</script>

<style scoped>

</style>