const tooltipBlocks = document.querySelectorAll(".tooltip-block");

if (tooltipBlocks.length > 0) {
    let tooltipOpen = false;
    tooltipBlocks.forEach(tooltip => {
        tooltip.addEventListener('click', (e) => {
            if (tooltipOpen) {
                if (tooltip.querySelector(".tooltip-wrap").classList.contains('d-none')) {
                    tooltipBlocks.forEach(block => {
                        block.querySelector(".tooltip-wrap").classList.add('d-none');
                    })
                    tooltip.querySelector(".tooltip-wrap").classList.remove('d-none');
                    tooltipOpen = true;
                } else {
                    tooltipBlocks.forEach(block => {
                        block.querySelector(".tooltip-wrap").classList.add('d-none');
                    })
                    tooltipOpen = false;
                }
            } else {
                tooltip.querySelector(".tooltip-wrap").classList.remove('d-none');
                tooltipOpen = true;
            }

        });
    })

    document.addEventListener('mousedown', (e) => {
        tooltipOpen && onClickChech(e, '.tooltip-block', tooltipOpen);
    });

    function onClickChech(e, blockTag, flag) {
        e = e || window.event;
        const blocks = document.querySelectorAll(blockTag);
        let target = e.target || e.srcElement;

        while (target.parentNode && target.tagName.toLowerCase() !== 'body') {
            if (target.classList.contains('tooltip-block')) {
                return false;
            }
            target = target.parentNode;
        }

        blocks.forEach(block => {
            block.querySelector(".tooltip-wrap").classList.add('d-none');
        })

        flag = !flag;
    }
}
