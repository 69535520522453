<template>
	<ul class="pagination">
		
		<li v-for="page in paginationData.pageRange" class="page-item" v-bind:class="page === paginationData.current ? 'active' : ''">
			<a class="page-link" v-on:click="pageClick(page)">{{ page }}</a>
		</li>
		
		<!--{% if next is defined %}
		<li class="page-item">
			<a aria-label="Next"
			   class="page-link"
			   href="{{ path(route, query|merge({(pageParameterName): next})) }}">
				<span aria-hidden="true"><i class="fas fa-chevron-right"></i></span>
			</a>
		</li>
		{% endif %}
		
		{% if last is defined and current != last %}
		{#<span class="last">
                <a href="{{ path(route, query|merge({(pageParameterName): last})) }}">&gt;&gt;</a>
            </span>#}
		{% endif %}
		</ul>
		{% endif %}-->
	
	</ul>
</template>

<script>
	export default {
		name: "Pagination",
		props: {
			paginationData: Object,
		},
		methods: {
			pageClick: function(page) {
				let self = this;
				
				if(page !== self.paginationData.current) {
					this.$root.$emit('paginate', page);
				}
			}
		}
	}
</script>

<style scoped>

</style>