const btnLang = document.querySelector(".lang-icon .lang-icon-flag");
const btnLangWrap = document.querySelector(".lang-icon");
const btnNotification = document.querySelector(".notification-block-header .fa-bell");

if (btnLang) {
    const eventClick = new Event('click', {bubbles: true});
    btnLang.addEventListener('click', () => {
        const navbarToggler = document.querySelector('.navbar-toggler');
        const navbarCollapse = document.querySelector('.navbar-collapse');

        if (navbarCollapse.classList.contains('show')) {
            navbarToggler.dispatchEvent(eventClick);
        }

        const btnOpenMenu = document.querySelector(".btn-open-menu");
        const userMenu = document.querySelector(".user-menu");
        if (userMenu && userMenu.classList.contains('show')) {
            btnOpenMenu.dispatchEvent(eventClick);
        }

        if (btnNotification && btnNotification.classList.contains('notification-block-open')) {
            btnNotification.dispatchEvent(eventClick);
        }
    });
    if(btnNotification){
        btnNotification.addEventListener('click', () => {

            if (btnLangWrap.classList.contains('show')) {
                btnLang.dispatchEvent(eventClick);
            }
        });
    }
}