var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-letters" }, [
    _c("div", { staticClass: "no-letters-content" }, [
      _c("p", { staticClass: "no-letters-content__p" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t"),
      ]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "no-letters-content__a", attrs: { href: "/jobs" } },
        [_vm._v("\n\t\t\t" + _vm._s(_vm.labelForJobs) + "\n\t\t")]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "no-letters-content__a", attrs: { href: "/cv" } },
        [_vm._v("\n\t\t\t" + _vm._s(_vm.labelForCvs) + "\n\t\t")]
      ),
    ]),
    _vm._v(" "),
    _c("img", {
      staticClass: "no-letters__img",
      attrs: { src: "/images/no-letters-img.svg", alt: "" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }