const Choices = require('choices.js');
import * as translations from '../Translations';

let mainSearchFormEl = document.querySelector('form.main-search');

let translationsEls = document.querySelectorAll('[data-type="translation"]');

function handleSwitchTabs() {
	let searchTabsEls = document.querySelector('ul.search-tabs');
	
	if (searchTabsEls && mainSearchFormEl) {
		let navLinks = searchTabsEls.getElementsByClassName('nav-link'),
			queryInputEl = mainSearchFormEl.querySelector('input#query'),
			trans = translations.getTranslations();
		
		let defaultPlaceholder = queryInputEl.getAttribute('placeholder');
		
		if (navLinks && navLinks.length > 0) {
			navLinks.forEach(function (item, key) {
				item.addEventListener('click', function (e) {
					let target = e.target.getAttribute('data-target');
					
					queryInputEl.setAttribute('placeholder', target !== 'companies' ? defaultPlaceholder : trans[queryInputEl.getAttribute('placeholder-company-key')]);
					
					mainSearchFormEl.setAttribute('action', '/' + target);
				})
			})
		}
	}
}

function renderLocationChoices() {
	let mainSearchFormEls = document.querySelectorAll('.main-search');
	
	if(mainSearchFormEls && mainSearchFormEls.length > 0) {
		mainSearchFormEls.forEach(function(mainSearchFormEl) {
			let locationSelectorEl = mainSearchFormEl.querySelector('[name="locationsList[]"]'),
				locationsTextEl = mainSearchFormEl.querySelector('[name="locations"]');
			
			let choicesParams = {
				placeholderValue: '',
				searchPlaceholderValue: '',
				removeItemButton: true,
				placeholder: true
			};
			
			if(locationSelectorEl) {
				let locationChoice = new Choices(locationSelectorEl, choicesParams);
			}
			
			locationSelectorEl.addEventListener(
				'change',
				function (event) {
					locationsTextEl.value = Array.from(locationSelectorEl.querySelectorAll("option:checked"), e => e.value).join(',');
					event.target.value = "";
				},
				false,
			);
			
			
		})
	}
}

// handleSwitchTabs();
renderLocationChoices();
