var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn btn-success btn-mr btn-sm",
      attrs: {
        "aria-controls": "showReplyForm",
        "aria-expanded": "false",
        "data-position": "top",
        "data-toggle": "collapse",
        href: "#showReplyForm",
        role: "button",
      },
      on: { click: _vm.linkClicked },
    },
    [_vm._v(_vm._s(_vm.label))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }