<template>
	<div>
        <div v-if="showLoader" class="text-center">
            <img src="/images/gifs/wait.gif" class="w-half" />
        </div>
		<div class="vacancy-management-block" ref="stick"
		     v-bind:class="[scrolled ? 'vacancy-management-block-fixed' : '']" v-if="jobs.items && !showLoader">
			<div class="checkbox-vacancy">
				<input id="select-all" type="checkbox" v-model="selectAll">
				<label for="select-all">{{ labelSelectAll }}</label>
			</div>
			<div class="vacancy-management-block-buttons batch-actions">
				<button :disabled="selectedJobs.length <= 0"
				        class="vacancy-management-block__btn vacancy-management-block__btn__update"
				        type="button"
				        v-on:click="batchDateRefresh">
					<span class="material-icons">update</span>
					<span class="text-for-btn">{{ labelRefreshDate }}</span>
				</button>
				<button :disabled="selectedJobs.length <= 0"
				        class="vacancy-management-block__btn vacancy-management-block__btn__delete"
				        type="button"
				        v-on:click="batchDelete">
					<span class="material-icons">delete</span>
					<span class="text-for-btn">{{ labelRemove }}</span>
				</button>
			</div>
		</div>
		<div class="table-response-vacancy" v-if="jobs.items && !showLoader">
			<div class="table-response-vacancy-header table-response-my-vacancy-header">
				<p class="col1">{{ labelTitle }}</p>
				<p class="col2">{{ labelStatus }}</p>
				<p class="col3">{{ labelCreatedAt }}</p>
				<p class="col4">&nbsp;</p>
			</div>
			<ul class="table-response-vacancy-body">
				
				<li class="table-response-vacancy-row-small table-response-vacancy-row"
				    v-bind:class="[job.promotedByColor ? 'paid-tariff' : '', job.promotedByBadge ? 'vip-paid-tariff' : '', selectedJobs.includes(job.shortId) ? 'border-success' : '']"
				    v-for="job in jobs.items"
				>
					<i class="fas fa-medal icon-vip-paid"></i>
					<div class="col1">
						<div class="checkbox-vacancy">
							<input :id="job.id"
							       :value="job.shortId"
							       type="checkbox"
							       v-model="selectedJobs">
							<label v-bind:for="job.id"></label>
						</div>
						<div>
							<p>
								<a v-bind:href="getUrl(job.title, job.shortId)">
									{{ job.title }}
								</a>
								<span class="ml-1 font-weight-light badge badge-warning"
								      data-placement="bottom" data-toggle="tooltip"
								      v-bind:title='labelArchivedTooltip' v-if="job.isArchived">
									{{ labelArchived }}
								</span>
							</p>
							<!--							<p>Vasilij Fedkin</p>-->
						</div>
					</div>
					<div class="col2">
						<p v-if="job.isPublished">
							<span class="rounded text-success border border-success px-2 py-1">{{ labelPublished }}</span>
							<i class="fas fa-check-circle"></i>
						</p>
						<p v-else>
							<span class="rounded text-danger tooltips border border-danger px-2 py-1">
	<!--														<span class="tooltiptext">{{ tooltipText }}</span>-->
								{{ labelNotPublished }}
							</span>
							<i class="fas fa-eye-slash"></i>
						</p>
					
					</div>
					<p class="col3">
						{{ formatDate(job.publishedAt) }}
					</p>
					<div class="col4">
						<button aria-expanded="false" aria-haspopup="true"
						        class="btn-vacancy-toggle" data-toggle="dropdown"
						        type="button" v-bind:id="'dropdownMenuButton'">
							<div></div>
							<div></div>
							<div></div>
						</button>
						<div aria-labelledby="dropdownMenuButton"
						     class="dropdown-menu dropdown-menu-right vacancy-list">
							
							<a class="dropdown-item"
							   data-action="refreshDate"
							   v-bind:data-id="job.id"
							   v-bind:href="getUrl(job.title, job.shortId, 'refresh')">
								{{ labelRefreshDate }}
							</a>
							
							<a class="dropdown-item"
							   v-bind:href="getUrl(job.title, job.shortId, 'edit')">
								{{ labelEdit }}
							</a>
							
							<a class="dropdown-item"
							   target="_blank"
							   v-bind:href="getUrl(job.title, job.shortId, 'preview')">
								{{ labelPreview }}
							</a>
							<a class="dropdown-item"
							   onclick="return confirm(labelConfirmRemove)"
							   v-bind:href="getUrl(job.title, job.shortId, 'remove')"
							>
								{{ labelRemove }}
							</a>
						</div>
					
					</div>
				</li>
			
			</ul>
		</div>
		<Pagination v-bind:pagination-data="jobs.pagination"
		            v-if="jobs.items && jobs.pagination.pageCount > 1" />
	</div>
</template>

<script>
	export * as slugify from '../../../../Components/Slugify'
	import * as axios from 'axios'
	import Pagination from "../../pagination/Pagination";
	
	export default {
		name: "Jobs",
		components: {
			Pagination
		},
		props: {
			labelSelectAll: String,
			labelRemove: String,
			labelPreview: String,
			labelTitle: String,
			labelStatus: String,
			labelCreatedAt: String,
			labelArchivedTooltip: String,
			labelArchived: String,
			labelConfirmRemove: String,
			labelPublished: String,
			labelNotPublished: String,
			labelRefreshDate: String,
			labelEdit: String,
		},
		data() {
			return {
				jobs: [],
				page: 1,
				selectedJobs: [],
				scrolled: false,
				fromTop: 20,
                showLoader: true,
			}
		},
		mounted() {
			this.$root.$on('paginate', (page) => {
				this.paginate(page);
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'auto'
				});
			});
			
			this.getList();
			
			let self = this;
			window.addEventListener('scroll', function (event) {
				self.onScroll();
			});
			
		},
		beforeDestroy() {
			window.removeEventListener('scroll');
		},
		methods: {
			getList: function (page = 1) {
				
			    this.showLoader = true;
			    
			    let self = this;
				
				axios.get('/jobs/api/my-jobs/' + page)
					.then(function (response) {
						self.jobs = response.data;
                        self.showLoader = false;
					})
			},
			formatDate: function (rawDate) {
				
				let date = new Date(rawDate);
				
				return ('0' + date.getDate()).slice(-2)
					+ '.'
					+ ('0' + (date.getMonth() + 1)).slice(-2)
					+ '.'
					+ date.getFullYear();
			},
			getUrl: function (title, code, type = 'preview') {
				let url = '';
				
				if (type === 'edit') {
					url = '/job/update/' + code
				} else if (type === 'remove') {
					url = '/account/my-jobs/delete/' + code
				} else if (type === 'refresh') {
					url = '/account/my-jobs/' + code + '/refresh'
				} else {
					url = '/job/' + title.slugify() + '-' + code;
				}
				
				return url
			},
			paginate: function (page) {
				this.page = page;
				
				this.getList(page);
			},
			batchDateRefresh: function () {
				let self = this;
				
				axios.post('/jobs/api/refresh', {
					codes: self.selectedJobs
				}).then(function (response) {
					self.getList(self.page);
					
					setTimeout(function () {
						self.selectedJobs = []
					}, 1500);
					
				}).catch(function (response) {
					console.error('Something went wrong');
				});
			},
			batchDelete: function () {
				let self = this;
				
				if (confirm(self.labelConfirmRemove)) {
					axios.post('/jobs/api/remove', {
						codes: self.selectedJobs
					}).then(function (response) {
						self.getList(self.page);
						
						setTimeout(function () {
							self.selectedJobs = []
						}, 1500);
					})
				}
			},
			onScroll: function () {
				let self       = this,
				    rect       = this.$refs.stick.getBoundingClientRect(),
				    blockToTop = rect.top - self.fromTop;
				
				this.scrolled = pageYOffset > blockToTop;
			},
		},
		computed: {
			selectAll: {
				get: function () {
					return this.jobs.items ? this.selectedJobs.length === this.jobs.items.length : false;
				},
				set: function (value) {
					let selected = [];
					
					if (value) {
						this.jobs.items.forEach(function (job) {
							selected.push(job.shortId);
						});
						
						this.selectedJobs = selected;
					} else {
						this.selectedJobs = [];
					}
				}
			}
		}
	}
</script>

<style scoped>

</style>