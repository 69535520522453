const Choices = require('choices.js');

export const enableChoicesSelect = function(filterFormEl) {

	let languageListEl = filterFormEl.querySelector('[name="langList[]"]'),
		langCodesEl = filterFormEl.querySelector('[name="languages"]'),
		locationsListEl = filterFormEl.querySelector('[name="locationsList[]"]'),
		locationsEl = filterFormEl.querySelector('[name="locations"]'),
		requiredLanguagesList = filterFormEl.querySelector('[name="requiredLanguagesList[]"]'),
		requiredLanguagesEl = filterFormEl.querySelector('[name="requiredLanguages"]');

	let choicesParams = {
		placeholderValue: '',
		searchPlaceholderValue: '',
		removeItemButton: true,
		placeholder: true
	};

	if (langCodesEl && languageListEl) {
		let languagesChoice = new Choices(languageListEl, choicesParams);
		langCodesEl.value.split(',').forEach(function(item) {
			languagesChoice.setChoiceByValue(item);
		});
	}


	if (locationsEl && locationsListEl) {
		let locationsChoice = new Choices(locationsListEl, choicesParams);
		locationsEl.value.split(',').forEach(function(item) {
			locationsChoice.setChoiceByValue(item);
		});
	}

	if (requiredLanguagesEl && requiredLanguagesList) {
		let	requiredLanguagesChoice = new Choices(requiredLanguagesList, choicesParams);
		requiredLanguagesEl.value.split(',').forEach(function(item) {
			requiredLanguagesChoice.setChoiceByValue(item);
		})
	}

}

export const handleChoices = function(filterFormEl) {

	if (filterFormEl) {
		let languageListEl = filterFormEl.querySelector('[name="langList[]"]'),
			langCodesEl = filterFormEl.querySelector('[name="languages"]'),
			locationsListEl = filterFormEl.querySelector('[name="locationsList[]"]'),
			locationsEl = filterFormEl.querySelector('[name="locations"]'),
			requiredLanguagesList = filterFormEl.querySelector('[name="requiredLanguagesList[]"]'),
			requiredLanguagesEl = filterFormEl.querySelector('[name="requiredLanguages"]');

		if (languageListEl && languageListEl) {
			languageListEl.addEventListener(
				'change',
				function (event) {
					langCodesEl.value = Array.from(languageListEl.querySelectorAll("option:checked"), e => e.value).join(',');
					event.target.value = "";
				},
				false
			);
		}

		if (locationsListEl && locationsListEl){
			locationsListEl.addEventListener(
				'change',
				function (event) {
					locationsEl.value = Array.from(locationsListEl.querySelectorAll("option:checked"), e => e.value).join(',');
					event.target.value = "";
				},
				false
			);
		}

		if (requiredLanguagesList && requiredLanguagesList) {
			requiredLanguagesList.addEventListener(
				'change',
				function (event) {
					requiredLanguagesEl.value = Array.from(requiredLanguagesList.querySelectorAll("option:checked"), e => e.value).join(',');
					event.target.value = "";
				},
				false
			)
		}

	}
}

export const handleApply = function(filterFormEl) {
	let applyButtonEl = filterFormEl.querySelector('[name="apply"]');

	if(applyButtonEl) {
		applyButtonEl.addEventListener('click', function(event) {
			Array.from(document.getElementsByClassName('choices__input')).forEach(function(item) {
				if(item.options) {
					item.options.forEach(function (item, key) {
						// console.log(item + ' ' + item.selected);
						item.selected = false;
					})
				}
			})
		})
	}
}