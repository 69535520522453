<template>
	<div class="vote-wrap">
		<p>{{ this.title }}</p>
		<ul>
			<li>
				<div v-on:click="handleLikeClick">
					<i class="fa-thumbs-up" v-bind:class="[isLikedByUser ? 'fas' : 'far']"></i>
					<span>{{ this.likeCounter }}</span>
				</div>
			</li>
			<li>
				<div v-on:click="handleDisLikeClick">
					<i class="fa-thumbs-down" v-bind:class="[isDisLikedByUser ? 'fas' : 'far']"></i>
					<span>{{ this.dislikeCounter }}</span>
				</div>
			</li>
		</ul>
	</div>
	
</template>

<script>
	import * as axios from 'axios';
	
	export default {
		name: "Vote",
		props: {
			code: String,
			likes: String,
			dislikes: String,
			title: String,
			vote: String
		},
		data() {
			return {
				likeCounter: parseInt(this.likes),
				dislikeCounter: parseInt(this.dislikes),
				isLikedByUser: parseInt(this.vote) === 1,
				isDisLikedByUser: parseInt(this.vote) === 0,
				canVote: true,
			}
		},
		mounted() {
		
		},
		methods: {
			handleLikeClick() {
				
				let self = this;
				
				if(!this.canVote) {
					return;
				}
				
				if(!this.isLikedByUser) {
					
					this.canVote = false;
					
					if(this.isDisLikedByUser) {
						this.dislikeCounter -= 1;
						this.isDisLikedByUser = false;
					}
					
					this.likeCounter += 1;
					this.isLikedByUser = true;
				}
				
				let url = '/api/review/'+ this.code +'/vote/like';
				
				axios.get(url)
					.then(function(response) {
						if(response.status === 200){
							self.canVote = true;
						}
					})
					.catch(function(error) {
						console.error(error);
					})
			},
			handleDisLikeClick() {
				
				let self = this;
				
				if(!this.canVote) {
					return;
				}
				
				if(!this.isDisLikedByUser) {
					
					this.canVote = false;
					
					if(this.isLikedByUser) {
						this.likeCounter -= 1;
						this.isLikedByUser = false;
					}
					
					let url = '/api/review/'+ this.code +'/vote/dislike';
					
					axios.get(url)
						.then(function(response) {
							self.canVote = true;
						})
						.catch(function(error) {
							console.error(error);
						});
					
					this.dislikeCounter += 1;
					this.isDisLikedByUser = true;

				}
			}
		},
	}
</script>

<style scoped>

</style>