const actionsBlock = document.querySelector('.vacancy-page.vacancy-page-mob .actions-block');

function offset(el) {
	const rect = el.getBoundingClientRect(),
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

if(actionsBlock) {
	const actionsBlockToTop = offset(actionsBlock).top - 82;
	const stickyBlock = document.querySelector('.vacancy-page .reply-block-sticky');
	window.addEventListener('scroll', function () {
		if (pageYOffset > actionsBlockToTop) {
			stickyBlock.classList.add('reply-block-sticky__fixed');
		} else if (pageYOffset < actionsBlockToTop){
			stickyBlock.classList.remove('reply-block-sticky__fixed');
		}
	});
}
