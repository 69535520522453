var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { on: { click: _vm.toggleList } }, [
      _c("i", {
        staticClass: "fas fa-bell",
        class: [_vm.notifications.length > 0 ? "animate" : ""],
      }),
    ]),
    _vm._v(" "),
    _vm.notifications.length > 0
      ? _c("div", { staticClass: "notifications-icon rounded-circle" })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notification-block-list", class: _vm.dropDownsClasses },
      [
        _c(
          "ul",
          _vm._l(_vm.notifications, function (message) {
            return _c("li", { staticClass: "notification-item" }, [
              _c("i", {
                staticClass:
                  "fas fa-info-circle info-icon text-info notification-type-icon",
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  class: [message.url ? "link text-primary" : ""],
                  on: {
                    click: function ($event) {
                      return _vm.notificationClick(message.id, message.url)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t" + _vm._s(message.message) + "\n\t\t\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("i", {
                staticClass: "fas fa-times mark-as-read",
                on: {
                  click: function ($event) {
                    return _vm.markAsRead(message.id)
                  },
                },
              }),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "btn-close-all" }, [
          _c("a", { attrs: { href: "#" }, on: { click: _vm.markAllAsRead } }, [
            _vm._v(_vm._s(_vm.markAllReadText)),
          ]),
        ]),
        _vm._v(" "),
        _vm.notifications.length <= 0
          ? _c("p", { staticClass: "no-notification-text" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.noItemsText) + "\n\t\t\t"),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }