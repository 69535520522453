const blocksReadMore = document.querySelectorAll(".block-read-more");

if (blocksReadMore.length > 0) {
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    let readMoreList = [];

    blocksReadMore.forEach(block => {
        readMoreList.push(block.querySelector(".read-more-text").innerHTML);
    });

    readMoreShow(vw, readMoreList);

    window.addEventListener('resize', function(event) {
        vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        readMoreShow(vw, readMoreList);
    }, true);

    function readMoreShow(vw, readMoreList) {
        blocksReadMore.forEach((block, i) => {
            let isOpen = false;
            const btn = block.querySelector(".read-more");
            const str = block.querySelector(".read-more-text");
            const strValTemp = readMoreList[i];
            const strLength = strValTemp.length;
            let strMaxLength = strLength;
            let maxLengthMob = parseInt(str.getAttribute('data-max-length'));

            if(vw < 768) {
                maxLengthMob ? strMaxLength = maxLengthMob : strMaxLength = 86;
            }

            if (strLength > strMaxLength) {
                str.innerHTML = checkString(false, strValTemp, strMaxLength);
                btn.classList.remove('d-none');
            } else {
                str.innerHTML = strValTemp;
                btn.classList.add('d-none');
            }

            btn.addEventListener("click", () => {
                block.classList.toggle('block-read-more__close');
                isOpen = !isOpen;
                str.innerHTML = checkString(isOpen, strValTemp, strMaxLength);
            });
        });
    }

    function checkString(isOpen, str, length) {
        if (!isOpen) {
            return str.substr(0, length);
        } else {
            return str;
        }
    }
}
