let showPasswordLinkEl = document.querySelectorAll('#show-password');

if(showPasswordLinkEl) {
	showPasswordLinkEl.forEach(btn=>{
		btn.addEventListener('click', function(e) {
			let passwordInputEl = btn.parentElement.parentNode.querySelectorAll('div.manage-password input');
			Array.from(passwordInputEl).forEach(function (passwordInput) {
				passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
			})
		})
	})
}
