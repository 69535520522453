var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "new-job-add-block-wrap",
        attrs: { "data-position": "block6" },
      },
      [
        _c(
          "p",
          {
            staticClass: "new-job-add-block-wrap-title",
            attrs: { id: "blockAdditional" },
          },
          [_vm._v("\n\t\tAdditional information\n\t")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group " }, [
          _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
            _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
              _c(
                "p",
                {
                  staticClass: "text-semibold on-mob-text-normal mb-3 mb-lg-0",
                },
                [_vm._v("\n\t\t\t\tLinks ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "new-job-add-block-wrap-row-right block-site-video-links",
              },
              [
                _c("div", { staticClass: "block-sitelinks " }, [
                  _c(
                    "p",
                    {
                      attrs: {
                        onclick: "this.parentNode.classList.toggle('add-link')",
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-link" }),
                      _vm._v(" Add a link to your website "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mt-3 mb-3 mb-lg-0" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "cv_form_webLink",
                        name: "cv_form[webLink]",
                        inputmode: "url",
                        placeholder: "Link to website",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "block-sitelinks " }, [
                  _c(
                    "p",
                    {
                      attrs: {
                        onclick: "this.parentNode.classList.toggle('add-link')",
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-video" }),
                      _vm._v(" Add a link to a video "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mt-3 mb-3 mb-lg-0" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "cv_form_videoUrl",
                        name: "cv_form[videoUrl]",
                        inputmode: "url",
                        placeholder:
                          "offer.https://www.youtube.com/watch?v=video_id",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-groupb-3 mb-lg-0" }, [
          _c("div", { staticClass: "new-job-add-block-wrap-row" }, [
            _c("div", { staticClass: "new-job-add-block-wrap-row-left" }, [
              _c(
                "label",
                { staticClass: "mb-2", attrs: { for: "cv_form_content" } },
                [_vm._v("\n\t\t\t\t\tCV content description\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "new-job-add-block-wrap-row-right" }, [
              _c("textarea", {
                staticClass: "form-control mt-0 mt-md-3",
                attrs: {
                  id: "cv_form_content",
                  name: "cv_form[content]",
                  rows: "10",
                  placeholder: "offer.form.content.placeholder",
                },
              }),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }