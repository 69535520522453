var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.errors.form < 0
      ? _c("div", { staticClass: "alert-success alert" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.replySuccessText) + "\n\t"),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isSent
      ? _c(
          "div",
          {
            staticClass:
              "card collapse rounded-sm p-3 mt-4 mb-3 vacancy-page-form reply-form",
            class: [_vm.collapsed ? "collapse" : ""],
            attrs: { id: "showReplyForm" },
          },
          [
            _c("div", { staticClass: "card-body p-0" }, [
              _c("div", { staticClass: "float-right text-black-50 pb-3" }, [
                _c("span", [_vm._v(_vm._s(_vm.formRequiredLabel))]),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  attrs: { method: "post", novalidate: "true" },
                  on: { submit: _vm.onSubmitForm },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: [_vm.errors.email ? "has-error" : ""],
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "text-black-50",
                          attrs: { for: "name" },
                        },
                        [_vm._v(_vm._s(_vm.nameLabel))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name",
                          },
                        ],
                        staticClass: "form-control",
                        class: [
                          _vm.errors.name > 0 ? "is-invalid text-danger" : "",
                          _vm.errors.name < 0 ? "is-valid" : "",
                        ],
                        attrs: {
                          id: "name",
                          name: "name",
                          required: "required",
                          type: "text",
                          placeholder: _vm.namePlaceholder,
                        },
                        domProps: { value: _vm.name },
                        on: {
                          change: _vm.validateName,
                          focusout: _vm.clearName,
                          keyup: _vm.validateName,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.name > 0
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v("Incorrect format"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-sm-6",
                        class: [_vm.errors.email ? "has-error" : ""],
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "text-black-50",
                            attrs: { for: "email" },
                          },
                          [_vm._v(_vm._s(_vm.emailLabel))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "form-control",
                          class: [
                            _vm.errors.email > 0
                              ? "is-invalid text-danger"
                              : "",
                            _vm.errors.email < 0 ? "is-valid" : "",
                          ],
                          attrs: {
                            id: "email",
                            name: "email",
                            required: "required",
                            type: "email",
                            placeholder: _vm.emailPlaceholder,
                          },
                          domProps: { value: _vm.email },
                          on: {
                            change: _vm.validateEmail,
                            keyup: _vm.validateEmail,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.email > 0
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v("Incorrect format"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-sm-6",
                        class: [_vm.errors.email ? "has-error" : ""],
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "text-black-50",
                            attrs: { for: "phone" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.phoneLabel) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone,
                              expression: "phone",
                            },
                          ],
                          staticClass: "form-control",
                          class: [
                            _vm.errors.phone > 0
                              ? "is-invalid text-danger"
                              : "",
                            _vm.errors.phone < 0 ? "is-valid" : "",
                          ],
                          attrs: {
                            id: "phone",
                            name: "phone",
                            required: "required",
                            type: "text",
                            placeholder: _vm.phonePlaceholder,
                          },
                          domProps: { value: _vm.phone },
                          on: {
                            change: _vm.validatePhone,
                            keyup: _vm.validatePhone,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phone = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.phone > 0
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v("Incorrect format"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.phoneHelp)),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: [_vm.errors.message ? "has-error" : ""],
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "text-black-50",
                          attrs: { for: "message" },
                        },
                        [_vm._v(_vm._s(_vm.messageLabel))]
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.message,
                            expression: "message",
                          },
                        ],
                        staticClass: "form-control",
                        class: [
                          _vm.errors.message > 0
                            ? "is-invalid text-danger"
                            : "",
                          _vm.errors.message < 0 ? "is-valid" : "",
                        ],
                        attrs: {
                          id: "message",
                          name: "message",
                          required: "required",
                          placeholder: _vm.messagePlaceholder,
                        },
                        domProps: { value: _vm.message },
                        on: {
                          change: _vm.validateMessage,
                          keyup: _vm.validateMessage,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.message = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.message > 0
                        ? _c("p", { staticClass: "text-danger" }, [
                            _vm._v("Incorrect format"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errors.form > 0
                    ? _c("div", { staticClass: "alert-danger alert" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.replyFailedText) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group btn-form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary margin-top-10-xs",
                        attrs: { id: "reply", name: "reply", type: "submit" },
                        on: { click: _vm.validateForm },
                      },
                      [
                        _c("i", { staticClass: "fa fa-paper-plane" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "vacancy-page-mob-hide" }, [
                          _vm._v(_vm._s(_vm.sendLabel)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-danger pull-right pull-left-xs margin-top-10-xs",
                        attrs: {
                          "aria-controls": "showReplyForm",
                          "aria-expanded": "true",
                          "data-target": "#showReplyForm",
                          "data-toggle": "collapse",
                          href: "#showReplyForm",
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-times" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "vacancy-page-mob-hide" }, [
                          _vm._v(_vm._s(_vm.cancelLabel)),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      id: "_token",
                      name: "_token",
                      type: "hidden",
                      value: "L-aTqKXk7JIz99FHte01SKQyx5Z6ZF3PivtiVnkmZuE",
                    },
                  }),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }