const Choices = require('choices.js');

let companyFormEl = document.getElementsByClassName('company-manage-form');

if(companyFormEl.length > 0) {
	let sectorsEl = companyFormEl[0].querySelector('#sectors');
	
	if(sectorsEl) {
		
		let choicesParams = {
			placeholderValue: '',
			searchPlaceholderValue: '',
			removeItemButton: true,
			placeholder: true
		};
		
		new Choices(sectorsEl, choicesParams);
	}
}