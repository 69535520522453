import { render, staticRenderFns } from "./Favorites.vue?vue&type=template&id=19094d97&scoped=true&"
import script from "./Favorites.vue?vue&type=script&lang=js&"
export * from "./Favorites.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19094d97",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\ruslan\\workmarket.eu\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19094d97')) {
      api.createRecord('19094d97', component.options)
    } else {
      api.reload('19094d97', component.options)
    }
    module.hot.accept("./Favorites.vue?vue&type=template&id=19094d97&scoped=true&", function () {
      api.rerender('19094d97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/Vue/components/offers/Favorites.vue"
export default component.exports