var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.agents.length > 0
        ? _c("p", { staticClass: "subscriptions-list__title" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.agents, function (agent) {
        return _c("div", { staticClass: "subscription-block" }, [
          _c(
            "label",
            {
              staticClass: "custom-checkbox custom-checkbox__sm",
              attrs: { for: agent.id },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: agent.isActive,
                    expression: "agent.isActive",
                  },
                ],
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: agent.id },
                domProps: {
                  checked: Array.isArray(agent.isActive)
                    ? _vm._i(agent.isActive, null) > -1
                    : agent.isActive,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = agent.isActive,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(agent, "isActive", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              agent,
                              "isActive",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(agent, "isActive", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.toggleStatus(agent.id, agent.token)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("i"),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "subscription-content" }, [
            _c(
              "p",
              [
                _vm._l(agent.sectors, function (sector) {
                  return _c("span", [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(sector.trim()) + "\n\t\t\t\t"
                    ),
                  ])
                }),
                _vm._v(" "),
                agent.sectors.length > 0
                  ? _c("span", { staticClass: "divider" })
                  : _vm._e(),
                _vm._v(" "),
                agent.salary.length > 0
                  ? _c("span", [_vm._v(_vm._s(agent.salary) + " €")])
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "p",
              _vm._l(agent.locations, function (location, key) {
                return _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(_vm.getLocation(location.trim()))
                  ),
                  key < agent.locations.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e(),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "p",
              _vm._l(agent.languages, function (language, key) {
                return _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(_vm.getLanguage(language.trim()))
                  ),
                  language.length > 0 && key < agent.languages.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _vm._m(0, true),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu dropdown-menu-right vacancy-list",
              attrs: { "aria-labelledby": "dropdownMenuButton" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function ($event) {
                      return _vm.unsubscribe(agent.id, agent.token)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.labelUnsubscribe) + "\n\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  on: {
                    click: function ($event) {
                      return _vm.toggleStatus(agent.id, agent.token)
                    },
                  },
                },
                [
                  agent.isActive
                    ? _c("span", [_vm._v(_vm._s(_vm.labelDisable))])
                    : _vm._e(),
                  _vm._v(" "),
                  agent.isActive === false
                    ? _c("span", [_vm._v(_vm._s(_vm.labelEnable))])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ])
      }),
      _vm._v(" "),
      _vm.agents && _vm.pagination.pageCount > 1
        ? _c("Pagination", { attrs: { "pagination-data": _vm.pagination } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn-vacancy-toggle",
        attrs: {
          type: "button",
          id: "dropdownMenuButton",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("div"), _vm._v(" "), _c("div"), _vm._v(" "), _c("div")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }