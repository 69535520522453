copyStringToBuffer = function (str) {
	let tmp, focus;
	tmp = document.createElement('INPUT');
	focus = document.activeElement;
	
	tmp.value = str;
	
	document.body.appendChild(tmp);
	tmp.select();
	document.execCommand('copy');
	document.body.removeChild(tmp);
	focus.focus();
};

export let copyStringToBuffer;